<template>
<div style="width:100%">
  <v-list-item class="pa-0">
    <v-list-item-content class="pa-0">
      {{ item.original }}
    </v-list-item-content>
    <v-list-item-action>
      <v-list-item-action-text>{{ rec_funciones_bytesToSize(item.tamanio) }}</v-list-item-action-text>
      <v-btn @click="download(item)" icon><v-icon color="grey lighten-1">mdi-cloud-download</v-icon></v-btn>
    </v-list-item-action>
  </v-list-item>
</div>
</template>
<script>
import recFunciones from '@/js/rec_funciones.js'
import recGestion from '@/js/rec_gestiones.js'
import recLog from '@/js/rec_logs.js'
export default {
  name: 'inbound_lectura_archivosComponent',
  components: {
  },
  mixins: [recGestion, recFunciones, recLog],
  props: ['color', 'item', 'tipo'],
  data: () => ({

  }),
  methods: {
    download (item) {
      if (this.tipo === 'GESTION') {
        this.rec_gestion_download(item.id, item.original)
      } else if (this.tipo === 'LOG') {
        this.rec_gestion_log_download(item.id, item.original)
      }
    }

  }
}
</script>
