<template>
  <div style="width:100%">
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-2">
          <v-autocomplete v-model="formbuscar.buscar" :items="items_busquedas" label="FILTROS"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="2" class="pr-1" >
          <v-autocomplete v-if="formbuscar.buscar === 'NIVELES'" v-model="formbuscar.nivel" :items="items_niveles" label="NIVELES" item-text="descripcion" item-value="descripcion"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="4">
          <v-row>
            <v-col cols="12" md="6">
              <v-tooltip bottom v-if="session.tipo === 'Interno'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn block v-bind="attrs" v-on="on" @click="nuevo_pqr"><v-icon>mdi-file-outline</v-icon> </v-btn>
                </template>
                <span>Crear PQR</span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" md="6">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="actualizar" block v-bind="attrs" v-on="on"> <v-icon>mdi-refresh</v-icon> </v-btn>
                </template>
                <span>Refrescar</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="formbuscar.buscar === 'AREAS'">
        <v-col cols="12" md="6">
          <v-autocomplete v-model="formbuscar.area" :items="items_areas" label="AREAS" item-text="descripcion" item-value="id"></v-autocomplete>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="formbuscar.buscar === 'AREAS'">
        <v-col cols="12" md="6">
          <v-autocomplete v-model="formbuscar.afiltro" :items="items_abusquedas" label="FILTROS"></v-autocomplete>
        </v-col>
      </v-row>
      <buscarForm v-if="formbuscar.buscar === 'BUSQUEDAS DE REGISTROS'" :rules="rules" :colores="colores" :session="session" :gestion="gestion" :items="items"></buscarForm>
      <br>
      <bandejegaListado :colores="colores" :session="session" :items_cerrados="items_cerrados" :items_creados="items_creados" :items_escalamientos="items_escalamientos"></bandejegaListado>
  </div>
</template>

<script>
import bandejegaListado from '@/components/bandejas/bandeja_pqrs_listadoComponent.vue'
import buscarForm from '@/components/inbound/inbound_busquedaComponent.vue'
import recForm from '@/js/rec_form.js'
export default {
  name: 'bandeja_pqrsComponent',
  components: {
    bandejegaListado,
    buscarForm
  },
  mixins: [recForm],
  props: ['items_creados', 'items_cerrados', 'formbuscar', 'items_areas', 'items_abusquedas', 'items_busquedas', 'items_niveles', 'items_escalamientos', 'colores', 'session'],
  data: () => ({
    items: { departamentos: [], busquedas: [], operadores: [], tipos: [], categorias: [], loading: false },
    gestion: { keyform: 0, id_usuario: 0, id_cerrado: 0, categoria_id: null, subcategoria_id: null, detalle_id: null, descripcion: '', segundos: 0, subestado_id: 1, escalado_id: 1, fechadiagnostico: null, fechaescalado: null, fechafinalizado: null, fechaparacerrar: null, fechacerrado: null, tiempo: 0 },
    rules: {}
  }),
  created () {
    this.rules = this.$store.getters.getRules
    this.rec_form_inbound()
  },
  methods: {
    actualizar () {
      this.$emit('refresh_listado')
    },
    nuevo_pqr () {
      this.$emit('new_inbound')
    }
  }
}
</script>
