<template>
<div style="width:100%">
  <!-- FORMULARIO DE GESTION DEL ASESOR -->
  <v-form ref="form">
    <v-row no-gutters>
      <v-col cols="12">
        <v-textarea v-model="form.descripcion" :rules="[rules.requerido]" label="DESCRIPCION"></v-textarea>
      </v-col>
    </v-row>
    <br>
    <v-row no-gutters>
      <v-col cols="12" md="6">
        <v-autocomplete v-model="objEstado" :rules="[rules.requerido]" v-on:input="input_estado(objEstado)" item-text="escestado.descripcion" item-value="escestado.id" :items="items_estados" label="ESTADO" return-object autocomplete="off"></v-autocomplete>
      </v-col>
    </v-row>
    <v-row no-gutters v-if="showErrorPausa">
      <v-col cols="12" md="6">
        <span class='red--text'><strong>TIEMPO YA ESTA VENCIDO PARA COLOCARLO EN PAUSA</strong></span>
      </v-col>
    </v-row>
    <br>
    <v-row no-gutters>
      <v-col cols="12">
        <uploadFile :tipo="'LOG'" :editable="true" :items_archivos="items_archivos" :colores="colores" :session="session" :keyform="keyform" :urlroute="'upload_cargar_editar'" @refresh_archivos="refrescar_archivos"></uploadFile>
      </v-col>
    </v-row>
    <br>
    <v-row no-gutters>
      <v-col cols="12">
        <v-btn @click="validar" :color="colores.primario" dark block>GUARDAR</v-btn>
      </v-col>
    </v-row>
  </v-form>
    <mensajesSave :colores="colores" :dialogo="dialogo" :items_errores="items_errores" @closed_mensaje_dialogo="cerrar_mensaje_dialogo"></mensajesSave>
</div>
</template>

<script>
import uploadFile from '@/components/widgets/uploadComponent.vue'
import recNiveles from '@/js/rec_niveles.js'
import recFunciones from '@/js/rec_funciones.js'
import recLogs from '@/js/rec_logs.js'
import mensajesSave from '@/components/widgets/mensajes_saveComponent.vue'
export default {
  name: 'inbound_lectura_pqrComponent',
  components: {
    mensajesSave,
    uploadFile
  },
  mixins: [recNiveles, recLogs, recFunciones],
  props: ['objs', 'colores', 'keyform', 'session', 'registro', 'pqr', 'items_estados', 'items_niveles'],
  data: () => ({
    alerta: { diagnostico: 0, tipod: '', escalado: 0, tipoe: '', finalizado: 0, tipof: '', total: 0, tipot: '', objCiudad: null, istransporte: 'NO' },
    gestion: { subestado_id: 1, fechadiagnostico: null, fechaescalado: null, fechafinalizado: null, fechaparacerrar: null, fechacerrado: null },
    form: { gestion_id: 0, id_usuario: 0, escestado_id: 0, descripcion: '' },
    items_archivos: [],
    objEstado: null,
    rules: {},
    items_errores: [],
    showErrorPausa: false,
    dialogo: { incompleto: false, pregunta: false, pregunta_anular: false, esperar: false, finalizo: false, barfinalizo: false, barfinalizoanulo: false, detalle: false, error: false, objPayload: null }
  }),
  created () {
    this.rules = this.$store.getters.getRules
  },
  computed: {
    validar_area () {
      let estado = false
      if (this.pqr.subestado_id === 4 && this.pqr.id_usuario === this.session.id) {
        estado = true
      } else {
        for (let i = 0; i < this.registro.escalamientos.length; i++) {
          if ((this.pqr.escalamientos[i].area_id === this.session.area && this.pqr.escalamientos[i].escestado_id === 2 && this.pqr.escalamientos[i].escestado_id !== 7)) {
            estado = true
          }
          if (this.session.area !== 100 && this.pqr.escalamientos[i].id_usuario === this.session.id && this.session.tipo === 'Interno' && this.pqr.escalamientos[i].consecutivo === 1 && this.pqr.escalamientos[i].escestado_id === 2) {
            estado = true
          }
          if ((this.session.area === 100 && (this.pqr.escalamientos[i].escestado_id === 7 || this.pqr.escalamientos[i].escestado_id === 11))) {
            estado = true
          }
          if (this.session.area !== 100 && this.session.tipo === 'Interno' && this.pqr.escalamientos[i].consecutivo === 1 && this.pqr.escalamientos[i].escestado_id === 9) {
            estado = true
          }
        }
      }
      return estado
    }
  },
  methods: {
    validar () {
      this.showErrorPausa = false
      if (this.$refs.form.validate()) {
        this.dialogo.pregunta = true
      } else {
        this.dialogo.incompleto = true
      }
    },
    cerrar_mensaje_dialogo (newvalue) {
      if (newvalue === 'PREGUNTA') {
        if (this.form.escestado_id === 10) {
          this.rec_log_guardar_cerrado()
        } else if (this.form.escestado_id === 14) {
          this.rec_log_guardar_seguimiento()
        } else {
          this.rec_log_guardar()
        }
      } else if (newvalue === 'CANCELARPREGUNTAR') this.dialogo.pregunta = false
      else if (newvalue === 'FINALIZO') {
        this.objEstado = null
        this.form.escestado_id = 0
        this.form.descripcion = ''
        this.items_archivos = []
        this.dialogo.finalizo = false
      }
    },
    input_estado (obj) {
      this.form.escestado_id = obj.escestado_id
      // this.$emit('update_niveles', obj.escestado_id)
      if (this.registro.accion !== '' && this.registro.accion !== null) {
        this.alerta.diagnostico = this.registro.accion.diasdiagnostico
        this.alerta.tipod = this.registro.accion.tipodiagnostico
        this.alerta.escalado = this.registro.accion.diasescalados
        this.alerta.tipoe = this.registro.accion.tipoescalados
        this.alerta.finalizado = this.registro.accion.diasfinalizado
        this.alerta.tipof = this.registro.accion.tipofinalizado
        this.alerta.total = this.registro.accion.diastotal
        this.alerta.tipot = this.registro.accion.tipototal
        this.alerta.objCiudad = this.pqr.gescontacto.ciudad
        this.alerta.istransporte = 'NO'
      } else {
        this.alerta.diagnostico = 0
        this.alerta.tipod = ''
        this.alerta.escalado = 0
        this.alerta.tipoe = ''
        this.alerta.finalizado = 0
        this.alerta.tipof = ''
        this.alerta.total = 0
        this.alerta.tipot = ''
        this.alerta.objCiudad = this.pqr.gescontacto.ciudad
        this.alerta.istransporte = 'NO'
      }

      this.rec_niveles_editar(obj.escestado_id)
    },
    refrescar_archivos (data) {
      for (let i = 0; i < data.length; i++) {
        this.items_archivos.push(data[i])
      }
    }

  }
}
</script>
