import axios from 'axios'
export default {
  methods: {
    rec_estadisticas_anio (pAnio) {
      this.loadingAnio = true
      const urlroute = this.$store.getters.getUrl + 'estadistica_anual/' + pAnio
      axios({
        url: urlroute,
        method: 'GET',
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        },
        data: null
      }).then(response => {
        console.log(response.data)
        this.items_anual = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingAnio = false
      })
    },
    rec_estadisticas_mes (pAnio, pMes) {
      this.loadingMes = true
      const urlroute = this.$store.getters.getUrl + 'estadistica_mes/' + pAnio + '/' + pMes
      axios({
        url: urlroute,
        method: 'GET',
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        },
        data: null
      }).then(response => {
        console.log(response.data)
        this.items_meses = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingMes = false
      })
    }

  }
}
