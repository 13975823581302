import axios from 'axios'
export default {
  methods: {
    rec_log_datos () {
      this.form.gestion_id = this.registro.id
      this.form.id_usuario = this.session.id
      this.gestion.subestado_id = 0
      this.gestion.fechacerrado = null
      if (this.form.escestado_id === 6) { // CERRAR CASO
        this.gestion.subestado_id = 4
        this.gestion.fechacerrado = null
      } else if (this.form.escestado_id === 7) {
        this.gestion.subestado_id = 5
        this.gestion.fechacerrado = null
      } else if (this.form.escestado_id === 11) { // ACEPTACION DE LA PAUSA
        this.gestion.subestado_id = 6
        this.gestion.fechacerrado = null
      } else if (this.form.escestado_id === 12) { // RECHAZO DE LA PAUSA
        this.gestion.subestado_id = 2
        this.gestion.fechacerrado = null
      } else if (this.form.escestado_id === 8) { // QUITAR PAUSA
        this.gestion.subestado_id = 2
        this.gestion.fechacerrado = null
      } else {
        this.gestion.subestado_id = 2
        this.gestion.fechacerrado = null
      }
    },
    rec_log_guardar () {
      this.rec_log_datos()
      this.dialogo.pregunta = false
      this.dialogo.esperar = true
      var urlroute = this.$store.getters.getUrl + 'logs'
      const data = {
        gestion: this.gestion,
        registro: this.pqr,
        form: this.form,
        niveles: this.items_niveles,
        keyform: this.keyform,
        id_usuario: this.session.id,
        tiempos: this.alerta
      }
      axios({
        url: urlroute,
        method: 'POST',
        data: data,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        this.$emit('update_registro', response.data)
        this.dialogo.finalizo = true
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.dialogo.esperar = false
      })
    },
    rec_log_datos_seguimiento () {
      this.form.gestion_id = this.registro.id
      this.form.id_usuario = this.session.id
    },
    rec_log_guardar_seguimiento () {
      this.rec_log_datos_seguimiento()
      this.dialogo.pregunta = false
      this.dialogo.esperar = true
      var urlroute = this.$store.getters.getUrl + 'seguimientos'
      const data = {
        form: this.form,
        keyform: this.keyform,
        id_usuario: this.session.id
      }
      axios({
        url: urlroute,
        method: 'POST',
        data: data,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        this.$emit('update_seguimiento', response.data)
        this.dialogo.finalizo = true
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.dialogo.esperar = false
      })
    },
    rec_log_cerrado_datos () {
      this.form.gestion_id = this.registro.id
      this.form.id_usuario = this.session.id
    },
    rec_log_guardar_cerrado () {
      this.rec_log_cerrado_datos()
      this.dialogo.pregunta = false
      this.dialogo.esperar = true
      var urlroute = this.$store.getters.getUrl + 'cierres'
      const data = {
        id: this.registro.id,
        form: this.form,
        registro: this.pqr,
        id_usuario: this.session.id
      }
      axios({
        url: urlroute,
        method: 'POST',
        data: data,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        this.objEstado = null
        this.form.escestado_id = 0
        this.form.descripcion = ''
        this.$emit('update_registro', response.data)
        this.dialogo.finalizo = true
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.dialogo.esperar = false
      })
    },
    rec_log_cerrado_backoffice_datos () {
      this.form.gestion_id = this.registro.id
      this.form.id_usuario = this.session.id
    },
    rec_log_guardar_backoffice () {
      this.rec_log_cerrado_backoffice_datos()
      this.dialogo.pregunta = false
      this.dialogo.esperar = true
      var urlroute = this.$store.getters.getUrl + 'cierres/' + this.registro.id
      const data = {
        form: this.form,
        id_usuario: this.session.id,
        registro: this.pqr
      }
      axios({
        url: urlroute,
        method: 'PUT',
        data: data,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        this.objEstado = null
        this.form.escestado_id = 0
        this.form.descripcion = ''
        this.$emit('backoffce_registro', response.data)
        this.dialogo.finalizo = true
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.dialogo.esperar = false
      })
    },
    rec_gestion_log_download (pId, archivo) {
      var urlroute = this.$store.getters.getUrl + 'log_download/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        },
        responseType: 'arraybuffer'
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', archivo) // set custom file name
        document.body.appendChild(link)
        link.click() // force download file without open new tab
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingAtenciones = false
      })
    }

  }
}
