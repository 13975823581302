<template>
<div style="width:100%">
  <v-list-item link class="pa-0">
        <v-row no-gutters>
            <v-col cols="12" md="1">
                <v-row no-gutters align="center">
                  <v-col cols="12" md="3">
                    <v-card width="25" height="110" color="pink" @click="abrirColores"></v-card>
                  </v-col>
                  <v-col cols="12" md="9" class="text-center" style="font-size:10px"> {{ item.id }} </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="2">
              <v-list-item-content class="pa-0">
                  <v-list-item-title><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ item.gescontacto.cedula }}</span></v-list-item-title>
                  <v-list-item-title><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ item.gescontacto.nombres }}</span></v-list-item-title>
                  <v-list-item-title><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ item.gescontacto.departamento.descripcion }}</span></v-list-item-title>
                  <v-list-item-title><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ item.gescontacto.celular  }}</span></v-list-item-title>
                  <v-list-item-title><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">KIT: {{ item.gescontacto.kit  }}</span></v-list-item-title>
              </v-list-item-content>
            </v-col>
            <v-col cols="12" md="3">
              <v-list-item-content class="pa-0">
                  <v-list-item-title v-if="item.accion" class="pa-1"><v-card :color="item.accion.prioridad.color" dark style="font-size:10px" class="text-center" >{{ item.accion.prioridad.descripcion }}</v-card></v-list-item-title>
                    <v-list-item-title><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ item.categoria.descripcion }}</span></v-list-item-title>
                    <v-list-item-title><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ item.subcategoria.descripcion }}</span></v-list-item-title>
                    <v-list-item-title><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ item.detalle.descripcion }}</span></v-list-item-title>
              </v-list-item-content>
            </v-col>
            <v-col cols="12" md="3">
              <v-row no-gutters><v-col class="pl-2"><v-icon size="15">mdi-calendar</v-icon><span class="pl-1" style="font-size:10px">CREADO: {{ rec_function_fecha_colombia(item.created_at) }}</span></v-col></v-row>
              <v-row no-gutters><v-col class="pl-2 pr-2"><v-card dark :color="item.subestado.color" style="font-size:10px" class="pl-2">ESTADO: {{ item.subestado.descripcion }}</v-card></v-col></v-row>
              <v-row no-gutters><v-col class="pl-2"><v-icon size="15">mdi-account-circle</v-icon><span class="pl-1" style="font-size:10px" v-if="item.usuario">{{ item.usuario.nombre_usuario + ' ' + item.usuario.apellido_usuario }}</span></v-col></v-row>
              <v-row no-gutters><v-col class="pr-2" v-if="oculta_cerrado(item.escalamientos)"><v-card class="pl-2" :color="alerta_cerrar(item.totalhoras, item.fechacerrado)" dark><v-icon size="15">mdi-calendar</v-icon><span class="pl-1" style="font-size:10px">CERRAR {{ rec_function_fecha_colombia(item.fechaparacerrar)}} {{ (item.fechacerrado !== null)? '' : (item.totalhoras>=1)?'VENCIDO POR ' + ((item.totalhoras === 1)? item.totalhoras + ' HORA': item.totalhoras + ' HORAS')  : ''  }} </span></v-card></v-col></v-row>
              <!-- <v-row no-gutters><v-col class="pr-2" v-if="ver_pausa(item.escalamientos)"><v-card class="pl-2" color="purple" dark><span class="pl-1" style="font-size:10px">EN PAUSA</span></v-card></v-col></v-row> -->
              <v-row no-gutters><v-col class="pr-2" v-if="ver_validacion(item.escalamientos)"><v-card class="pl-2" color="deep-orange" dark><span class="pl-1" style="font-size:10px">EN VALIDACIÓN</span></v-card></v-col></v-row>
              <v-row no-gutters><v-col class="pl-2" v-if="item.fechacerrado"><v-icon size="15">mdi-calendar</v-icon><span class="pl-1" style="font-size:10px">CERRADO: {{ rec_function_fecha_colombia(item.fechacerrado) }}</span></v-col></v-row>
            </v-col>
            <v-col cols="12" md="3" align-self="center">
              <v-row no-gutters align="center">
                <v-col cols="12" md="7">
                      <v-row v-for="(escalamiento, i) in item.escalamientos" no-gutters align="center" :key="i">
                        <v-col cols="12" md="8">
                          <v-list-item-content class="pa-0">
                              <v-list-item-title class="pa-0">
                                <v-card style="font-size:10px" width="100%" :color="escalamiento.escestado.color" dark class="text-center">
                                  {{ escalamiento.area.descripcion  }}
                                </v-card>
                              </v-list-item-title>
                          </v-list-item-content>
                        </v-col>
                        <v-col cols="12" md="4" align-self="center" class='text--center'>
                          <v-list-item-content class="pa-0">
                            <v-list-item-title class="pa-0">
                              <v-card style="font-size:11px" class='text--center'>
                                  <center><v-icon size="15" v-if="escalamiento.fecha_asignado">mdi-clock-time-eight</v-icon>{{ rec_funciones_fecha_restar(rec_function_fecha_colombia(escalamiento.fecha_asignado), rec_function_fecha_colombia(escalamiento.fecha_cerrado)) }}</center>
                              </v-card>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-col>
                      </v-row>
                      <v-list-item-content class="pa-0">
                        <v-list-item-title class="pa-0"><v-card :color="alerta_cerrar(item.escaladohoras, item.fechacerrado)" dark style="font-size:10px" class="text-center" >{{ rec_function_fecha_colombia(item.fechaescalado) }}</v-card></v-list-item-title>
                      </v-list-item-content>
                </v-col>
                <v-col cols="12" md="5" class="text-center">
                  <v-btn @click="verPqr(item)" icon color="blue"><v-icon>mdi-pencil</v-icon></v-btn>
                </v-col>
              </v-row>
            </v-col>
        </v-row>
      </v-list-item>
</div>
</template>

<script>
import recFunciones from '@/js/rec_funciones.js'
export default {
  name: 'bandeja_pqr_listadoComponent',
  components: {
  },
  mixins: [recFunciones],
  props: ['item'],
  data: () => ({
  }),
  methods: {
    ver_pausa (item) {
      let showPausa = false
      for (let i = 0; i < item.length; i++) {
        if (item[i].escestado_id === 7) {
          showPausa = true
        }
      }
      return showPausa
    },
    ver_validacion (item) {
      let showValidacion = false
      for (let i = 0; i < item.length; i++) {
        if (item[i].escestado_id === 9) {
          showValidacion = true
        }
      }
      return showValidacion
    },
    oculta_cerrado (item) {
      let showPausa = true
      let showValidacion = true
      for (let i = 0; i < item.length; i++) {
        if (item[i].escestado_id === 7) {
          showPausa = false
          return showPausa
        } if (item[i].escestado_id === 9) {
          showValidacion = false
          return showValidacion
        }
      }
      return showPausa
    },
    abrirColores () {
      this.$emit('openColores')
    },
    verPqr (item) {
      this.$emit('viewPqr', item)
    },
    alerta_cerrar (totalhoras, pFechacerrado) {
      let color = ''
      if (pFechacerrado !== null) {
        color = 'black'
      } else {
        if (totalhoras < -24) {
          color = 'green'
        } else if (totalhoras >= -24 && totalhoras <= -4) { // 3 horas
          color = 'amber'
        } else if (totalhoras >= -3) {
          color = 'red'
        } else {
          color = 'red'
        }
      }

      return color
    }
  }
}
</script>
