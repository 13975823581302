<template>
<div style="width:100%">
  <v-card>
    <v-toolbar :color="colores.secundario" dark dense>
      <v-toolbar-title>CONTACTO</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <v-text-field label="KIT REPORTADO" :value="contacto.kit" filled readonly></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="pr-1">
            <v-text-field label="DIVIPOLE" :value="contacto.divipole" filled readonly></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <v-text-field label="DEPARTAMENTO" :value="contacto.departamento.descripcion" filled readonly></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="pr-1">
            <v-text-field label="MUNICIPIO" :value="contacto.ciudad.descripcion" filled readonly></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <v-text-field label="REGISTRADURIA/PMI" :value="contacto.registraduria.descripcion" filled readonly></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="pr-1">
            <v-text-field label="DIRECCION" :value="contacto.direccion" filled readonly></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <v-text-field label="TIPO" :value="contacto.tipo.descripcion" filled readonly></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="pr-1">
            <v-text-field label="OPERADOR INICIAL" :value="contacto.operador.descripcion" filled readonly></v-text-field>
          </v-col>
        </v-row>
      <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <v-text-field label="CEDULA" :value="contacto.cedula" filled readonly></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <v-text-field label="NOMBRES" :value="contacto.nombres" filled readonly></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field label="CONTACTO" :value="contacto.apellidos" filled readonly></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <v-text-field label="CELULAR" :value="contacto.celular" filled readonly></v-text-field>
          </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</div>
</template>

<script>
// import textComponent from '@/components/widgets/textComponent.vue'
export default {
  name: 'inbound_lectura_contactoComponent',
  components: {
    // textComponent
  },
  props: ['colores', 'contacto'],
  data: () => ({

  }),
  created () {

  },
  methods: {
  }
}
</script>
