<template>
<div style="width:100%">
        <pqrTipificacion :tokenNuevo="tokenNuevo" :itemsObj="itemsObj" :gestion="gestion" :contacto="contacto" :llamada="llamada" :colores="colores" :session="session" :rules="rules" :alerta="alerta" :items="items" :segundos="segundos" @remover_niveles="remover_niveles" @new_niveles="agregarNiveles"  @nextDiagnostico="siguienteDiagnostico"></pqrTipificacion>
        <br>
        <br>
        <v-card v-if="showDiagnostico">
          <v-toolbar :color="colores.secundario" dark dense>
            <v-toolbar-title>DIAGNOSTICO</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items class="pt-1">
                <horaComponent :tokenTiempo="tokenNuevo" :estadoTiempo="estadoTiempo" @eventotiempo="tiempo_pqr"></horaComponent>
                <v-btn v-if="!showTiempo" icon dark @click="editar"><v-icon>mdi-pencil</v-icon></v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form">
                <v-row no-gutters>
                  <v-col cols="12" md="3" class="pr-2" v-for="(item, index) in itemsObj.items_niveles" :key="index">
                    <nivelCard :color="item.color" :escestado="item.escestado" :titulo="'NIVEL ' + item.consecutivo" :descripcion="item.area"></nivelCard>
                  </v-col>
                </v-row>
                <br>
                <br>
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-textarea :disabled="!editable" v-model="gestion.descripcion" :rules="[rules.requerido]" label="DESCRIPCION" rows="5"></v-textarea>
                  </v-col>
                </v-row>
                <br>
                <v-row no-gutters>
                  <v-col cols="12" md="6">
                    <v-autocomplete :disabled="!editable" v-model="objEstado" :rules="[rules.requerido]" v-on:input="input_estado(objEstado)" item-text="escestado.descripcion" item-value="escestado.id" :items="itemsObj.items_estados" label="ESTADO" return-object autocomplete="off"></v-autocomplete>
                  </v-col>
                </v-row>
                <br>
                <v-row no-gutters :disabled="!editable">
                  <v-col cols="12">
                    <uploadFile :editable="editable" :tipo="'GESTION'" :colores="colores" :items_archivos="items_archivos" :session="session" :keyform="gestion.keyform" :urlroute="'upload_cargar_nuevo'" @refresh_archivos="refrescar_archivos"></uploadFile>
                  </v-col>
                </v-row>
                <br>
                <v-row v-if="showTiempo && editable4">
                  <v-col cols="12">
                    <v-btn @click="validar" block :color="colores.primario" dark>GUARDAR</v-btn>
                  </v-col>
                </v-row>
            </v-form>
          </v-card-text>
        </v-card>
  <mensajesSave :colores="colores" :dialogo="dialogo" :items_errores="items_errores" @closed_mensaje_dialogo="cerrar_mensaje_dialogo"></mensajesSave>
  <mensajeLogin :dialogoLogin="dialogoLogin" @close_login="cerrar_login"></mensajeLogin>
</div>
</template>

<script>
import mensajeLogin from '@/components/widgets/mensajes_loginComponent.vue'
import nivelCard from '@/components/inbound/inbound_nivelesComponent.vue'
import uploadFile from '@/components/widgets/uploadComponent.vue'
import recTipicaciones from '@/js/rec_tipicaciones.js'
import recGestiones from '@/js/rec_gestiones.js'
import recNiveles from '@/js/rec_niveles.js'
import recFunciones from '@/js/rec_funciones.js'
import pqrTipificacion from '@/components/inbound/inbound_pqr_tipificacionComponent.vue'
import horaComponent from '@/components/widgets/horaComponent.vue'
import mensajesSave from '@/components/widgets/mensajes_saveComponent.vue'
export default {
  name: 'dialogo_inboundComponent',
  components: {
    mensajeLogin,
    pqrTipificacion,
    uploadFile,
    horaComponent,
    nivelCard,
    mensajesSave
  },
  mixins: [recTipicaciones, recNiveles, recFunciones, recGestiones],
  props: ['tokenNuevo', 'gestion', 'contacto', 'llamada', 'colores', 'items_archivos', 'session', 'rules', 'alerta', 'items', 'segundos'],
  data: () => ({
    showDiagnostico: false,
    loadingSubcategoria: false,
    items_subcategorias: [],
    objEstado: null,
    itemsObj: { items_estados: [], items_niveles: [] },
    loadingSubCateroria: false,
    items_subcaterias: [],
    objDetalle: null,
    loadingDetalle: false,
    items_detalles: [],
    tiempo: 0,
    tokenInbound: 0,
    showTiempo: true,
    estadoTiempo: 1,
    editable: true,
    editable4: true,
    dialogoLogin: false,
    items_errores: [],
    dialogo: { incompleto: false, pregunta: false, pregunta_anular: false, esperar: false, finalizo: false, barfinalizo: false, barfinalizoanulo: false, detalle: false, error: false, objPayload: null }
  }),
  tokenNuevo: {
    immediate: true,
    handler (newVal, oldVal) {
      // this.showTiempo = false
      // this.estadoTiempo = 2
      // this.editable = false
      // this.editable4 = false
    }
  },
  methods: {
    remover_niveles () {
      this.itemsObj.items_niveles = []
    },
    agregarNiveles (pniveles, color, escestadoId, escestado) {
      this.itemsObj.items_niveles.push({ gestion_id: null, fecha_asignado: null, fecha_cerrado: null, id_usuario: this.session.id, consecutivo: pniveles.consecutivo, area_id: pniveles.area.id, area: pniveles.area.descripcion, escestado_id: escestadoId, escestado: escestado, color: color })
    },
    siguienteDiagnostico () {
      this.showDiagnostico = true
    },
    tiempo_pqr (ptiempo) {
      this.gestion.tiempodiagnostico = ptiempo
    },
    editar () {
      this.estadoTiempo = 1
      this.showTiempo = true
      this.editable = true
      this.editable4 = true
    },
    validar () {
      if (this.$refs.form.validate()) {
        this.gestion.id_usuario = this.session.id
        if (this.gestion.id_usuario === 0) {
          this.dialogo.incompleto = true
          this.dialogoLogin = true
        } else {
          this.dialogo.pregunta = true
        }
      } else {
        this.dialogo.incompleto = true
      }
    },
    cerrar_mensaje_dialogo (newvalue) {
      if (newvalue === 'PREGUNTA') this.rec_gestion_nuevo()
      else if (newvalue === 'CANCELARPREGUNTAR') this.dialogo.pregunta = false
      else if (newvalue === 'FINALIZO') {
      } else if (newvalue === 'DETALLES') {
        this.dialogo.detalle = false
        this.$emit('new_pqr')
      }
    },
    refrescar_archivos (data) {
      this.$emit('add_archivos', data)
    },
    eventosNiveles (pestadoId) {
      this.rec_niveles_nuevo(pestadoId)
    },
    input_estado (obj) {
      this.gestion.escalado_id = 0
      this.gestion.subestado_id = 0
      this.gestion.fechacerrado = null
      if (obj.escestado_id === 6) { // CERRAR CASO
        this.gestion.escalado_id = 1
        this.gestion.subestado_id = 1
        this.gestion.fechacerrado = null
      } else if (obj.escestado_id === 9) { // VALIDACION
        this.gestion.escalado_id = 3
        this.gestion.subestado_id = 3
        this.gestion.fechacerrado = null
      } else {
        this.gestion.escalado_id = 2
        this.gestion.subestado_id = 2
        this.gestion.fechacerrado = null
      }
      this.eventosNiveles(obj.escestado_id)
    },
    cerrar_login () {
      this.dialogoLogin = false
    }
  }
}
</script>
