import axios from 'axios'
export default {
  methods: {
    rec_tipificaciones_categorias (pCategoriaId) {
      this.loadingSubcategoria = true
      this.items_subcategorias = []
      var urlroute = this.$store.getters.getUrl + 'listados_principal/' + pCategoriaId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        this.items_subcategorias = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingSubcategoria = false
      })
    },
    rec_tipificaciones_detalles (pSubCategoriaId) {
      this.loadingDetalle = true
      this.items_detalles = []
      var urlroute = this.$store.getters.getUrl + 'listados_principal/' + pSubCategoriaId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        this.items_detalles = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingDetalle = false
      })
    }

  }
}
