<template>
  <div style="width:100%">
    <v-dialog v-model="dialogLectura" fullscreen hide-overlay persistent transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark :color="colores.primario">
        <v-btn icon dark @click="cerrarVenta"><v-icon>mdi-close</v-icon></v-btn>
        <v-toolbar-title>INCIDENTE</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-2" >
        <center v-if="loadingRegistro == true"><v-progress-circular :size="150" :width="7" :color="colores.primario" indeterminate ></v-progress-circular></center>
        <inboundLectura v-if="registro" :keyform="keyform" :session="session" :colores="colores" :pqr="pqr" :registro="registro" :items_estados="items_estados" :items_niveles="items_niveles" @refresh_niveles="refrescar_niveles" @refresh_registro="refrescar_registro"></inboundLectura>
      </v-card-text>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
import recGestiones from '@/js/rec_gestiones.js'
import recNiveles from '@/js/rec_niveles.js'
import recFunciones from '@/js/rec_funciones.js'
import inboundLectura from '@/components/inbound/inbound_lecturaComponent.vue'
export default {
  name: 'dialogo_inboundComponent',
  components: {
    inboundLectura
  },
  mixins: [recGestiones, recNiveles, recFunciones],
  props: ['dialogLectura', 'colores', 'session', 'token', 'gestion_id'],
  data: () => ({
    registro: null,
    pqr: null,
    loadingRegistro: false,
    items_estados: [],
    items_niveles: []
  }),
  watch: {
    token: {
      immediate: false,
      handler (newVal, oldVal) {
        this.keyform = this.rec_funciones_key()
        this.rec_gestion_buscar_id(this.gestion_id)
      }
    }
  },
  created () {
  },
  methods: {
    refrescar_niveles (pestadoId) {
      this.rec_niveles_editar(pestadoId)
    },
    refrescar_registro (data) {
      console.log(data)
      this.registro = data.registro
      this.pqr = data.pqr
      this.rec_gestion_niveles_entidad(data.registro.escalamientos)
      this.rec_gestion_estados_entidad(data.registro.escalamientos, data.estados, data.registro)
    },
    cerrarVenta () {
      this.$emit('closed_ventana')
    }

  }
}
</script>
