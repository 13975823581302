import axios from 'axios'
export default {
  methods: {
    rec_reportes_gestiones (pAnio, pMes, pIdUsuario) {
      var urlroute = this.$store.getters.getUrl + 'excel_gestiones'
      const data = { anio: pAnio, mes: pMes, id_usuario: pIdUsuario }
      this.loadingExcel = true
      axios({
        url: urlroute,
        method: 'POST',
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        },
        data: data,
        responseType: 'arraybuffer'
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'gestiones.xlsx') // set custom file name
        document.body.appendChild(link)
        link.click() // force download file without open new tab
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingExcel = false
      })
    }

  }
}
