<template>
<div style="width:100%">
  <v-card>
    <v-toolbar dark :color="colores.primario">
      <v-toolbar-title>CREAR PQR</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-card-text>
          <horaComponent :tokenTiempo="tokenInbound" :estadoTiempo="1" @eventotiempo="tiempo_pqr"></horaComponent>
        </v-card-text>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text class="pt-2">
      <formInbound :llamada="llamada" :tokenInbound="tokenInbound" :colores="colores" :tiempo="tiempo" :session="session" :items="items" @new_pqr="nueva_pqr"></formInbound>
    </v-card-text>
  </v-card>
</div>
</template>

<script>
import horaComponent from '@/components/widgets/horaComponent.vue'
import formInbound from '@/components/inbound/inbound_formComponent.vue'
import recForm from '@/js/rec_form.js'

export default {
  name: 'Vicidial',
  components: {
    horaComponent,
    formInbound
  },
  mixins: [recForm],
  data: () => ({
    session: { id: 0, nombres: '', apellidos: '', correo: '', identificacion: '', tipo: '', area: 0, permisos: [] },
    colores: null,
    llamada: { gestion_id: 0, celular: '', callercode: '', tipo: '', cedula: '', uniqueid: '', security_phrase: '', lead_id: '', vendor_id: '', closer: '', server_ip: '', closecallid: '', xfercallid: '' },
    tiempo: 0,
    loadingAtenciones: true,
    items: { departamentos: [], operadores: [], tipos: [], busquedas: [], loading: false },
    items_busqueda: [],
    tokenInbound: 0
  }),
  created () {
    this.session = this.$store.getters.getSession
    this.llamada.celular = this.$route.query.celular || ''
    this.llamada.callercode = this.$route.query.callercode || ''
    this.llamada.tipo = this.$route.query.tipo || ''
    this.llamada.cedula = this.$route.query.cedula || ''
    this.llamada.uniqueid = this.$route.query.uniqueid || ''
    this.llamada.security_phrase = this.$route.query.security_phrase || ''
    this.llamada.lead_id = this.$route.query.lead_id || ''
    this.llamada.vendor_id = this.$route.query.vendor_id || ''
    this.llamada.closer = this.$route.query.closer || ''
    this.llamada.server_ip = this.$route.query.server_ip || ''
    this.llamada.closecallid = this.$route.query.closecallid || ''
    this.llamada.xfercallid = this.$route.query.xfercallid || ''
    this.colores = this.$store.getters.getColores
    this.tokenInbound = this.tokenInbound + 1
    this.rec_form_inbound()
  },
  methods: {
    tiempo_pqr (ptiempo) {
      this.tiempo = ptiempo
    },
    nueva_pqr () {
      this.tokenInbound = this.tokenInbound + 1
    }
  }
}
</script>
