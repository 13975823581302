import axios from 'axios'
export default {
  methods: {
    rec_gestion_inicializacion () {
      this.gestion.keyform = 0
      this.gestion.id_usuario = 0
      this.gestion.categoria_id = null
      this.gestion.subcategoria_id = null
      this.gestion.detalle_id = null
      this.gestion.descripcion = ''
      this.gestion.segundos = 0
      this.gestion.subestado_id = 1
      this.gestion.escalado_id = 1
      this.gestion.fechadiagnostico = null
      this.gestion.fechaescalado = null
      this.gestion.fechafinalizado = null
      this.gestion.fechaparacerrar = null
      this.gestion.fechacerrado = null
      this.gestion.tiempo = 0
      this.gestion.tiempodiagnostico = 0
      this.gestion.fechadurocontacto = null
      this.gestion.fechadurotipificacion = null
      this.gestion.fechadurodiagnostico = null

      this.contacto.gestion_id = null
      this.contacto.kit = ''
      this.contacto.divipole = ''
      this.contacto.departamento_id = null
      this.contacto.ciudad_id = null
      this.contacto.registraduria_id = null
      this.contacto.direccion = ''
      this.contacto.operador_id = null
      this.contacto.tipo_id = null
      this.contacto.cedula = ''
      this.contacto.nombres = ''
      this.contacto.apellidos = ''
      this.contacto.celular = ''
      this.contacto.tiempo = 0
      this.alerta.objCiudad = null
      this.alerta.istransporte = 'NO'
      this.itemsObj.items_niveles = []
    },
    rec_gestion_datos () {
      this.gestion.id_usuario = this.session.id
      this.gestion.segundos = this.segundos
      this.estadoTiempo = 3
      this.editable = false
    },
    rec_gestion_nuevo () {
      this.rec_gestion_datos()
      this.dialogo.pregunta = false
      this.dialogo.esperar = true
      this.dialogo.objPayload = null
      var urlroute = this.$store.getters.getUrl + 'gestiones'
      const data = {
        gestion: this.gestion,
        contacto: this.contacto,
        niveles: this.itemsObj.items_niveles,
        tiempos: this.alerta,
        llamada: this.llamada
      }
      axios({
        url: urlroute,
        method: 'POST',
        data: data,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        this.dialogo.detalle = true
        this.dialogo.objPayload = response.data
        this.rec_gestion_inicializacion()
        this.gestion.keyform = this.rec_funciones_key()
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.dialogo.esperar = false
      })
    },
    rec_gestion_buscar (pTipo, pDato) {
      this.loadingBusqueda = true
      this.items_registros = []
      var urlroute = this.$store.getters.getUrl + 'gestiones_consultas/' + pTipo + '/' + pDato
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        this.items_registros = response.data
        // this.rec_gestion_buscar_entidad(response.data)
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingBusqueda = false
      })
    },
    rec_gestion_buscar_id (pId) {
      this.loadingRegistro = true
      this.registro = null
      var urlroute = this.$store.getters.getUrl + 'gestiones_registro/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        this.registro = response.data.registro
        this.pqr = response.data.pqr
        this.rec_gestion_niveles_entidad(response.data.registro.escalamientos)
        this.rec_gestion_estados_entidad(response.data.registro.escalamientos, response.data.estados, this.registro)
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingRegistro = false
      })
    },
    rec_gestion_buscar_ids (pIds) {
      this.loadingBusqueda = true
      this.items_incidentes = []
      this.objs.chk = []
      var urlroute = this.$store.getters.getUrl + 'gestiones_ids/' + pIds
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        console.log(response.data)
        this.items_incidentes = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingBusqueda = false
      })
    },
    rec_gestion_niveles_entidad (escalamientos) {
      this.items_niveles = []
      // this.items_niveles = escalamientos.map(item => { return item })// [...escalamientos]
      // this.items_niveles = [...escalamientos]
      for (let i = 0; i < escalamientos.length; i++) {
        this.items_niveles.push(escalamientos[i])
      }
    },
    rec_gestion_estados_entidad (escalamientos, estados, registro) {
      this.items_estados = []
      if (registro.subestado_id === 4) {
        this.items_estados = estados.cierrefinal
      } else {
        const t = (escalamientos.length === 0) ? 0 : (escalamientos.length - 1)
        for (let i = 0; i < escalamientos.length; i++) {
          if (escalamientos[i].escestado_id === 2) {
            if (escalamientos[i].area_id === 1) {
              this.items_estados = estados.primernivel
            } else if (i < t) {
              this.items_estados = (registro.pausas.length >= 1) ? estados.nomaspausas : estados.niveles
            } else {
              this.items_estados = (registro.pausas.length >= 1) ? estados.nomaspausas : estados.cerrar
              // this.items_estados = (registro.pausas.length >= 1) ? estados.cerrar.filter(item => { return (item.id !== 11) }) : estados.cerrar
            }
          } else if (escalamientos[i].escestado_id === 7) {
            this.items_estados = estados.aceptarpausas
          } else if (escalamientos[i].escestado_id === 11) {
            this.items_estados = estados.quitarpausa
          } else if (escalamientos[i].escestado_id === 9) {
            if (i < t) {
              this.items_estados = estados.quitarvalidacion
            } else {
              this.items_estados = estados.quitarvalidacion
              this.items_estados.splice(0, 1)
            }
          }
        }
      }

      if (this.session.tipo !== 'Externo') {
        for (let i = 0; i < this.items_estados.length; i++) {
          if (this.items_estados[i].id === 3) {
            this.items_estados.splice(i, 1)
          }
        }
      }
    },
    rec_gestion_download (pId, archivo) {
      var urlroute = this.$store.getters.getUrl + 'gestion_download/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        },
        responseType: 'arraybuffer'
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', archivo) // set custom file name
        document.body.appendChild(link)
        link.click() // force download file without open new tab
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingAtenciones = false
      })
    }
  }
}
