<template>
<div style="width:100%">
<v-card>
  <v-card-text class="pt-1 pr-4 pl-4 pb-1">
      <v-form ref="formbusqueda">
          <v-row no-gutters>
            <v-col cols="12" md="4" class="pr-1">
              <v-autocomplete v-model="tipo_id" :rules="[rules.requerido]" :items="items.busquedas" :loading="items.loading" item-value="id" item-text="descripcion" label="* TIPO BUSQUEDA" autocomplete="off"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" class="pr-1">
              <v-text-field v-model="dato" :rules="[rules.requerido]" label="* DATO A BUSCAR" autocomplete="off"></v-text-field>
            </v-col>
            <v-col cols="12" md="1">
            </v-col>
            <v-col cols="12" md="1" align-self="center">
              <v-btn :loading="loadingBusqueda" @click="buscar"> <v-icon>mdi-magnify</v-icon> </v-btn>
            </v-col>
          </v-row>
      </v-form>
    </v-card-text>
    <v-card-text>
      <bandejegaListado :colores="colores" :session="session" :items_creados="items_registros"></bandejegaListado>
    </v-card-text>
</v-card>
</div>
</template>

<script>
import bandejegaListado from '@/components/bandejas/bandeja_pqrs_listadoComponent.vue'
import recGestiones from '@/js/rec_gestiones.js'
export default {
  name: 'inbound_busquedaComponent',
  components: {
    bandejegaListado
  },
  mixins: [recGestiones],
  props: ['items', 'gestion', 'session', 'colores', 'rules'],
  data: () => ({
    tipo_id: 0,
    dato: '',
    loadingBusqueda: false,
    items_registros: []
  }),
  created () {

  },
  methods: {
    buscar () {
      if (this.$refs.formbusqueda.validate()) {
        this.rec_gestion_buscar(this.tipo_id, this.dato.trim())
      }
    }
  }
}
</script>
