<template>
  <div style="width:100%">
    <v-dialog v-model="objs.dialogAsignar" width="700px" hide-overlay persistent transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark :color="colores.primario">
          <v-btn icon dark @click="cerrarVenta"><v-icon>mdi-close</v-icon></v-btn>
          <v-toolbar-title>ASESORES</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-4">
          <v-progress-linear v-if="loadingAsesor" :color="colores.primario" indeterminate height="6"></v-progress-linear>
          <div v-else class="pt-4" style="height: 270px;overflow-y: scroll;">
            <div v-for="(item, index) in items_asesores" :key="index" >
              <div style="height: 10px;"></div>
                <v-row no-gutters>
                  <v-col cols="12" md="1"> <v-avatar :color="colores.primario" class="white--text" size="30">{{ index + 1 }}</v-avatar> </v-col>
                  <v-col cols="12" md="8">
                    {{ item.nombre_usuario + ' ' + item.apellido_usuario }}
                  </v-col>
                  <v-col cols="12" md="3" class="text-center">
                    <v-btn @click="guardar(item)" small dark :color="colores.primario">ACERPTAR</v-btn>
                  </v-col>
                </v-row>
                <div style="height: 10px;"></div>
                <v-divider></v-divider>
            </div>
          </div>
        </v-card-text>
      </v-card>
  </v-dialog>
  <mensajesSave :colores="colores" :dialogo="dialogo" :items_errores="items_errores" @closed_mensaje_dialogo="cerrar_mensaje_dialogo"></mensajesSave>
  </div>
</template>

<script>
import recFunciones from '@/js/rec_funciones.js'
import recModificaciones from '@/js/rec_modificaciones.js'
import recForm from '@/js/rec_form.js'
import mensajesSave from '@/components/widgets/mensajes_saveComponent.vue'
export default {
  name: 'reasignacion_dialogoComponent',
  components: {
    mensajesSave
  },
  mixins: [recFunciones, recModificaciones, recForm],
  props: ['objs', 'colores'],
  data: () => ({
    items_asesores: [],
    loadingAsesor: false,
    items_errores: [],
    asesor_id: null,
    dialogo: { incompleto: false, pregunta: false, pregunta_anular: false, esperar: false, finalizo: false, barfinalizo: false, barfinalizoanulo: false, detalle: false, error: false, objPayload: null }
  }),
  created () {
    this.rec_form_asignar()
  },
  methods: {
    guardar (item) {
      console.log(item)
      this.asesor_id = item.id_usuario
      this.dialogo.pregunta = true
    },
    cerrar_mensaje_dialogo (newvalue) {
      if (newvalue === 'PREGUNTA') this.rec_modificaciones_reasignar()
      else if (newvalue === 'CANCELARPREGUNTAR') this.dialogo.pregunta = false
      else if (newvalue === 'FINALIZO') {
        this.dialogo.finalizo = false
        this.cerrarVenta()
        this.$emit('update_busqueda')
      } else if (newvalue === 'DETALLES') {
        this.dialogo.detalle = false
      }
    },
    cerrarVenta () {
      this.objs.dialogAsignar = false
    }

  }
}
</script>
