<template>
<div style="width:100%">
  <v-card>
    <v-toolbar :color="colores.secundario" dark dense>
      <v-toolbar-title>TIPIFICACION</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="pt-1">
          <horaComponent :tokenTiempo="tokenInbound" :estadoTiempo="estadoTiempo" @eventotiempo="tiempo_pqr"></horaComponent>
          <v-btn v-if="!showTiempo" icon dark @click="editar"><v-icon>mdi-pencil</v-icon></v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text>
      <v-form ref="form">
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
              <v-autocomplete :disabled="!editable" v-model="gestion.categoria_id" :rules="[rules.requerido]" v-on:input="input_categoria(gestion.categoria_id)" item-text="descripcion" item-value="id" :items="items.categorias" :loading="items.loading" label="* CATEGORIA" autocomplete="off"></v-autocomplete>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
              <v-autocomplete :disabled="!editable" v-model="gestion.subcategoria_id" :rules="[rules.requerido]" v-on:input="input_subcategoria(gestion.subcategoria_id)" item-text="descripcion" item-value="id" :items="items_subcategorias" :loading="loadingSubcategoria" label="* SUBCATEGORIA" autocomplete="off"></v-autocomplete>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
              <v-autocomplete :disabled="!editable" v-model="objDetalle" :rules="[rules.requerido]" v-on:input="input_detalle(objDetalle)" item-text="descripcion" item-value="id" :items="items_detalles" :loading="loadingDetalle" label="* DETALLE" return-object autocomplete="off"></v-autocomplete>
          </v-col>
        </v-row>
        <br>
        <v-row v-if="showTiempo && editable4">
          <v-col cols="12">
            <v-btn @click="siguiente" block :color="colores.primario" dark>SIGUIENTE</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
  <mensajesSave :colores="colores" :dialogo="dialogo" :items_errores="items_errores" @closed_mensaje_dialogo="cerrar_mensaje_dialogo"></mensajesSave>
  <mensajeLogin :dialogoLogin="dialogoLogin" @close_login="cerrar_login"></mensajeLogin>
</div>
</template>

<script>
import mensajeLogin from '@/components/widgets/mensajes_loginComponent.vue'
import recTipicaciones from '@/js/rec_tipicaciones.js'
import recGestiones from '@/js/rec_gestiones.js'
import recNiveles from '@/js/rec_niveles.js'
import recFunciones from '@/js/rec_funciones.js'
import horaComponent from '@/components/widgets/horaComponent.vue'
import mensajesSave from '@/components/widgets/mensajes_saveComponent.vue'
export default {
  name: 'dialogo_inboundComponent',
  components: {
    mensajeLogin,
    horaComponent,
    mensajesSave
  },
  mixins: [recTipicaciones, recNiveles, recFunciones, recGestiones],
  props: ['tokenNuevo', 'gestion', 'contacto', 'llamada', 'colores', 'session', 'rules', 'alerta', 'items', 'segundos', 'itemsObj'],
  data: () => ({
    loadingSubcategoria: false,
    items_subcategorias: [],
    objEstado: null,
    items_estados: [],
    loadingSubCateroria: false,
    items_subcaterias: [],
    objDetalle: null,
    loadingDetalle: false,
    items_detalles: [],
    tiempo: 0,
    tokenInbound: 0,
    showTiempo: true,
    estadoTiempo: 1,
    editable: true,
    editable4: true,
    dialogoLogin: false,
    items_errores: [],
    dialogo: { incompleto: false, pregunta: false, pregunta_anular: false, esperar: false, finalizo: false, barfinalizo: false, barfinalizoanulo: false, detalle: false, error: false, objPayload: null }
  }),
  tokenNuevo: {
    immediate: true,
    handler (newVal, oldVal) {
      // this.showTiempo = false
      // this.estadoTiempo = 2
      // this.editable = false
      // this.editable4 = true
    }
  },
  methods: {
    tiempo_pqr (ptiempo) {
      this.gestion.tiempo = ptiempo
    },
    editar () {
      this.estadoTiempo = 1
      this.showTiempo = true
      this.editable = true
      this.editable4 = true
    },
    siguiente () {
      if (this.$refs.form.validate()) {
        this.estadoTiempo = 3
        this.editable = false
        this.editable4 = false
        this.$emit('nextDiagnostico')
      } else {
        this.dialogo.incompleto = true
      }
    },
    refrescar_archivos (data) {
      this.$emit('add_archivos', data)
    },
    input_categoria (pcategoriaId) {
      this.items_subcaterias = []
      this.items_detalles = []
      this.objEstado = null
      this.items_estados = []
      this.objDetalle = null
      this.rec_tipificaciones_categorias(pcategoriaId)
    },
    input_subcategoria (pSubCategoriaId) {
      // this.$emit('remover_niveles')
      this.items_detalles = []
      this.objDetalle = null
      this.items_estados = []
      this.objEstado = null
      this.rec_tipificaciones_detalles(pSubCategoriaId)
    },
    input_detalle (obj) {
      if (obj.accion !== '' && obj.accion !== null) {
        this.alerta.diagnostico = obj.accion.diasdiagnostico
        this.alerta.tipod = obj.accion.tipodiagnostico
        this.alerta.escalado = obj.accion.diasescalados
        this.alerta.tipoe = obj.accion.tipoescalados
        this.alerta.finalizado = obj.accion.diasfinalizado
        this.alerta.tipof = obj.accion.tipofinalizado
        this.alerta.total = obj.accion.diastotal
        this.alerta.tipot = obj.accion.tipototal
      } else {
        this.alerta.diagnostico = 0
        this.alerta.tipod = ''
        this.alerta.escalado = 0
        this.alerta.tipoe = ''
        this.alerta.finalizado = 0
        this.alerta.tipof = ''
        this.alerta.total = 0
        this.alerta.tipot = ''
      }

      this.objEstado = null
      this.itemsObj.items_estados = obj.niveles[0].gruestados
      this.crearNiveles(obj.niveles)
      this.gestion.detalle_id = obj.id
    },
    crearNiveles (pniveles) {
      this.itemsObj.items_niveles = []
      let color = 'blue-grey'
      let escestadoId = 1
      let escestado = 'EN ESPERA'

      for (let i = 0; i < pniveles.length; i++) {
        if (i === 0) {
          color = 'amber'
          escestadoId = 2
          escestado = 'EN PROCESO'
        } else {
          color = 'blue-grey'
          escestado = 'EN ESPERA'
          escestadoId = 1
        }
        this.$emit('new_niveles', pniveles[i], color, escestadoId, escestado)
      }
    },
    eventosNiveles (pestadoId) {
      this.rec_niveles_nuevo(pestadoId)
    },
    input_estado (obj) {
      this.gestion.escalado_id = 0
      this.gestion.subestado_id = 0
      this.gestion.fechacerrado = null
      if (obj.escestado_id === 6) { // CERRAR CASO
        this.gestion.escalado_id = 1
        this.gestion.subestado_id = 1
        this.gestion.fechacerrado = null
      } else if (obj.escestado_id === 9) { // VALIDACION
        this.gestion.escalado_id = 3
        this.gestion.subestado_id = 3
        this.gestion.fechacerrado = null
      } else {
        this.gestion.escalado_id = 2
        this.gestion.subestado_id = 2
        this.gestion.fechacerrado = null
      }
      this.eventosNiveles(obj.escestado_id)
    },
    cerrar_login () {
      this.dialogoLogin = false
    },
    cerrar_mensaje_dialogo (newvalue) {

    }
  }
}
</script>
