import axios from 'axios'
export default {
  methods: {
    rec_registraduria_ciudad (pCiudadId) {
      var urlroute = this.$store.getters.getUrl + 'registradurias_ciudad/' + pCiudadId
      this.loadingRegistraduria = true
      this.items_registradurias = []
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        this.items_registradurias = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingRegistraduria = false
      })
    },
    rec_registraduria_id (pId) {
      var urlroute = this.$store.getters.getUrl + 'registradurias_id/' + pId
      this.loadingRegistraduria = true
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        this.rec_registraduria_entidad(response.data)
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingRegistraduria = false
      })
    },
    rec_registraduria_entidad (data) {
      this.contacto.direccion = data.direccion
    }
  }
}
