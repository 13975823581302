export default {
  methods: {
    rec_niveles_nuevo (pestadoId) {
      if (pestadoId === 3) { // DEVUELTO

      } else if (pestadoId === 4) { // SIGUIENTE NIVEL
        this.itemsObj.items_niveles[1].color = 'amber'
        this.itemsObj.items_niveles[1].escestado_id = 2
        this.itemsObj.items_niveles[1].escestado = 'EN PROCESO'

        this.itemsObj.items_niveles[0].color = 'teal'
        this.itemsObj.items_niveles[0].escestado_id = 5
        this.itemsObj.items_niveles[0].escestado = 'NIVEL CERRADO'
      } else if (pestadoId === 6) { // CERRAR CASO
        this.itemsObj.items_niveles[0].escestado_id = 6
        this.itemsObj.items_niveles[0].escestado = 'CERRAR CASO'
        this.itemsObj.items_niveles[0].color = 'teal'

        if (this.itemsObj.items_niveles.length > 1) {
          this.itemsObj.items_niveles[1].color = 'blue-grey'
          this.itemsObj.items_niveles[1].escestado_id = 1
          this.itemsObj.items_niveles[1].escestado = 'EN ESPERA'
        }
      } else if (pestadoId === 2) { // EN PROCESO
        this.itemsObj.items_niveles[0].color = 'amber'
        this.itemsObj.items_niveles[0].escestado_id = 6
        this.itemsObj.items_niveles[0].escestado = 'EN PROCESO'

        if (this.itemsObj.items_niveles.length > 1) {
          this.itemsObj.items_niveles[1].color = 'blue-grey'
          this.itemsObj.items_niveles[1].escestado_id = 1
          this.itemsObj.items_niveles[1].escestado = 'EN ESPERA'
        }
      } else if (pestadoId === 9) { // EN VALIDACION
        this.itemsObj.items_niveles[0].color = 'deep-orange'
        this.itemsObj.items_niveles[0].escestado_id = 9
        this.itemsObj.items_niveles[0].escestado = 'EN VALIDACIÓN'

        if (this.itemsObj.items_niveles.length > 1) {
          this.itemsObj.items_niveles[1].color = 'blue-grey'
          this.itemsObj.items_niveles[1].escestado_id = 1
          this.itemsObj.items_niveles[1].escestado = 'EN ESPERA'
        }
      }
    },
    rec_niveles_editar (pestadoId) {
      // const escalamientos = [...this.pqr.escalamientos]
      const escalamientos = this.pqr.escalamientos.map(item => { return item })
      for (let i = 0; i < escalamientos.length; i++) {
        const { escestado } = escalamientos[i]
        this.items_niveles[i].escestado_id = escestado.id
        this.items_niveles[i].escestado.color = escestado.color
        this.items_niveles[i].escestado.descripcion = escestado.descripcion
        this.items_niveles[i].escestado.id = escestado.id
      }

      let proceso = 0
      for (let i = 0; i < escalamientos.length; i++) {
        if (escalamientos[i].escestado_id === 2) {
          proceso = i
          break
        } else if (escalamientos[i].escestado_id === 7) {
          proceso = i
          break
        } else if (escalamientos[i].escestado_id === 11) {
          proceso = i
          break
        }
      }

      if (pestadoId === 3) { // DEVUELTO
        this.items_niveles[(proceso - 1)].escestado.color = 'amber'
        this.items_niveles[(proceso - 1)].escestado_id = 2
        this.items_niveles[(proceso - 1)].escestado.id = 2
        this.items_niveles[(proceso - 1)].escestado.descripcion = 'EN PROCESO'

        this.items_niveles[proceso].escestado.color = 'blue-grey'
        this.items_niveles[proceso].escestado.descripcion = 'EN ESPERA'
        this.items_niveles[proceso].escestado_id = 1
        this.items_niveles[proceso].escestado.id = 1
      } else if (pestadoId === 4) { // SIGUIENTE NIVEL
        this.items_niveles[proceso].escestado.color = 'teal'
        this.items_niveles[proceso].escestado.descripcion = 'NIVEL CERRADO'
        this.items_niveles[proceso].escestado_id = 5
        this.items_niveles[proceso].escestado.id = 5

        this.items_niveles[(proceso + 1)].escestado.color = 'amber'
        this.items_niveles[(proceso + 1)].escestado_id = 2
        this.items_niveles[(proceso + 1)].escestado.id = 2
        this.items_niveles[(proceso + 1)].escestado.descripcion = 'EN PROCESO'
      } else if (pestadoId === 6) { // CERRAR CASO
        this.items_niveles[proceso].escestado.color = 'teal'
        this.items_niveles[proceso].escestado.descripcion = 'CERRAR CASO'
        this.items_niveles[proceso].escestado_id = 6
        this.items_niveles[proceso].escestado.id = 6
      } else if (pestadoId === 7) { // PAUSA
        this.items_niveles[proceso].escestado.color = 'purple'
        this.items_niveles[proceso].escestado.descripcion = 'SOLICITAR PAUSA'
        this.items_niveles[proceso].escestado_id = 7
        this.items_niveles[proceso].escestado.id = 7
      } else if (pestadoId === 8) { // QUITAR PAUSA
        this.items_niveles[proceso].escestado.color = 'amber'
        this.items_niveles[proceso].escestado_id = 13
        this.items_niveles[proceso].escestado.id = 13
        this.items_niveles[proceso].escestado.descripcion = 'EN PROCESO'
      } else if (pestadoId === 9) { // EN VALIDACION
        this.items_niveles[proceso].escestado.color = 'deep-orange'
        this.items_niveles[proceso].escestado.descripcion = 'EN VALIDACIÓN'
        this.items_niveles[proceso].escestado_id = 9
        this.items_niveles[proceso].escestado.id = 9

        this.items_niveles[(proceso + 1)].escestado.color = 'blue-grey'
        this.items_niveles[(proceso + 1)].escestado_id = 1
        this.items_niveles[(proceso + 1)].escestado.id = 1
        this.items_niveles[(proceso + 1)].escestado.descripcion = 'EN ESPERA'
      } else if (pestadoId === 12) { // RECHAZAR LA PAUSA
        this.items_niveles[proceso].escestado.color = 'amber'
        this.items_niveles[proceso].escestado_id = 2
        this.items_niveles[proceso].escestado.id = 2
        this.items_niveles[proceso].escestado.descripcion = 'EN PROCESO'
      }
    }
  }
}
