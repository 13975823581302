<template>
<div style="width:100%">
    <v-form ref="form">
    <v-row no-gutters>
      <v-col cols="12">
        <v-textarea v-model="form.descripcion" :rules="[rules.requerido]" label="DESCRIPCION"></v-textarea>
      </v-col>
    </v-row>
    <br>
    <v-row no-gutters>
      <v-col cols="12" md="6">
        <v-autocomplete v-model="form.escestado_id" :rules="[rules.requerido]" item-text="descripcion" item-value="id" :items="items_estados" label="ESTADO" autocomplete="off"></v-autocomplete>
      </v-col>
    </v-row>
    <br>
    <v-row no-gutters>
      <v-col cols="12">
        <uploadFile :tipo="'LOG'" :editable="true" :items_archivos="items_archivos" :colores="colores" :session="session" :keyform="keyform" :urlroute="'upload_cargar_editar'" @refresh_archivos="refrescar_archivos"></uploadFile>
      </v-col>
    </v-row>
    <br>
    <v-row>
      <v-col cols="12" md="6">
      <v-btn @click="cancelar" block :color="colores.primario" dark>CANCELAR</v-btn>
    </v-col>
      <v-col cols="12" md="6">
        <v-btn @click="validar" :color="colores.primario" dark block>GUARDAR</v-btn>
      </v-col>
    </v-row>
  </v-form>
  <mensajesSave :colores="colores" :dialogo="dialogo" :items_errores="items_errores" @closed_mensaje_dialogo="cerrar_mensaje_dialogo"></mensajesSave>
</div>
</template>

<script>
import uploadFile from '@/components/widgets/uploadComponent.vue'
import recNiveles from '@/js/rec_niveles.js'
import recFunciones from '@/js/rec_funciones.js'
import recForm from '@/js/rec_form.js'
import recLogs from '@/js/rec_logs.js'
import mensajesSave from '@/components/widgets/mensajes_saveComponent.vue'
export default {
  name: 'inbound_lectura_pqrComponent',
  components: {
    mensajesSave,
    uploadFile
  },
  mixins: [recNiveles, recLogs, recFunciones, recForm],
  props: ['objs', 'colores', 'keyform', 'session', 'registro', 'items_niveles'],
  data: () => ({
    form: { gestion_id: null, id_usuario: null, descripcion: '', escestado_id: null },
    items_archivos: [],
    items_estados: [],
    objEstado: null,
    rules: {},
    items_errores: [],
    showErrorPausa: false,
    dialogo: { incompleto: false, pregunta: false, pregunta_anular: false, esperar: false, finalizo: false, barfinalizo: false, barfinalizoanulo: false, detalle: false, error: false, objPayload: null }
  }),
  created () {
    this.rules = this.$store.getters.getRules
    this.rec_form_backoffice()
  },
  computed: {
    validar_area () {
      let estado = false
      if (this.pqr.subestado_id === 4 && this.pqr.id_usuario === this.session.id) {
        estado = true
      } else {
        for (let i = 0; i < this.registro.escalamientos.length; i++) {
          if ((this.pqr.escalamientos[i].area_id === this.session.area && this.pqr.escalamientos[i].escestado_id === 2 && this.pqr.escalamientos[i].escestado_id !== 7)) {
            estado = true
          }
          if (this.session.area !== 100 && this.pqr.escalamientos[i].id_usuario === this.session.id && this.session.tipo === 'Interno' && this.pqr.escalamientos[i].consecutivo === 1 && this.pqr.escalamientos[i].escestado_id === 2) {
            estado = true
          }
          if ((this.session.area === 100 && (this.pqr.escalamientos[i].escestado_id === 7 || this.pqr.escalamientos[i].escestado_id === 11))) {
            estado = true
          }
          if (this.session.area !== 100 && this.session.tipo === 'Interno' && this.pqr.escalamientos[i].consecutivo === 1 && this.pqr.escalamientos[i].escestado_id === 9) {
            estado = true
          }
        }
      }
      return estado
    }
  },
  methods: {
    cancelar () {
      this.$emit('backoffce_cancelar')
    },
    validar () {
      this.showErrorPausa = false
      if (this.$refs.form.validate()) {
        this.dialogo.pregunta = true
      } else {
        this.dialogo.incompleto = true
      }
    },
    cerrar_mensaje_dialogo (newvalue) {
      if (newvalue === 'PREGUNTA') {
        this.rec_log_guardar_backoffice()
      } else if (newvalue === 'CANCELARPREGUNTAR') this.dialogo.pregunta = false
      else if (newvalue === 'FINALIZO') {
        this.form.escestado_id = null
        this.form.descripcion = ''
        this.items_archivos = []
        this.dialogo.finalizo = false
      }
    },
    refrescar_archivos (data) {
      for (let i = 0; i < data.length; i++) {
        this.items_archivos.push(data[i])
      }
    }

  }
}
</script>
