<template>
<div style="width:100%">
  <div v-for="(item, index) in items_incidentes" :key="index + 10000">
      <itemListaado :objs="objs" :item="item"></itemListaado>
      <v-divider></v-divider>
  </div>
</div>
</template>

<script>
import itemListaado from '@/components/estadisticas/reasignacion_itemComponent.vue'
import recFunciones from '@/js/rec_funciones.js'
export default {
  name: 'reasignacion_listadoComponent',
  components: {
    itemListaado
  },
  mixins: [recFunciones],
  props: ['items_incidentes', 'objs'],
  data: () => ({
  }),
  methods: {
  }
}
</script>
