<template>
  <div style="width:100%">
    <v-dialog v-model="dialogReasignar" fullscreen hide-overlay persistent transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark :color="colores.primario">
          <v-btn icon dark @click="cerrarVenta"><v-icon>mdi-close</v-icon></v-btn>
          <v-toolbar-title>REASIGNACION DE INCIDENCIAS</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-4">
          <v-card>
              <v-card-text class="pt-1 pr-4 pl-4 pb-1">
                  <v-form ref="formbusqueda">
                      <v-row no-gutters>
                        <v-col cols="12" md="4" class="pr-1">
                          <v-text-field v-model="dato" persistent-hint :rules="[rules.requerido]" hint="Ejemplo: 10, 20, 30" label="* IDs" autocomplete="off"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="1">
                        </v-col>
                        <v-col cols="12" md="1" align-self="center">
                          <v-btn :disabled="isDato" :loading="loadingBusqueda" @click="buscar"> <v-icon>mdi-magnify</v-icon> </v-btn>
                        </v-col>
                        <v-col cols="12" md="1" align-self="center">
                          <v-btn :disabled="isInformacion" @click="asignar"> <v-icon>mdi-account-group</v-icon> </v-btn>
                        </v-col>
                      </v-row>
                  </v-form>
                </v-card-text>
                <v-card-text>
                  <reasignacionListadoComponent :objs="objs" :items_incidentes="items_incidentes"></reasignacionListadoComponent>
                </v-card-text>
            </v-card>
        </v-card-text>
        <reasignacionAsignarComponent :colores="colores" :objs="objs" @update_busqueda="buscar"></reasignacionAsignarComponent>
      </v-card>
  </v-dialog>
  </div>
</template>

<script>
import recFunciones from '@/js/rec_funciones.js'
import recGestiones from '@/js/rec_gestiones.js'
import reasignacionListadoComponent from '@/components/estadisticas/reasignacion_listadoComponent.vue'
import reasignacionAsignarComponent from '@/components/estadisticas/reasignacion_asignarComponent.vue'

export default {
  name: 'reasignacion_dialogoComponent',
  components: {
    reasignacionListadoComponent,
    reasignacionAsignarComponent
  },
  mixins: [recFunciones, recGestiones],
  props: ['dialogReasignar', 'colores'],
  data: () => ({
    dato: '',
    loadingBusqueda: false,
    objs: { chk: [], dialogAsignar: false },
    items_incidentes: [],
    rules: null
  }),
  created () {
    this.rules = this.$store.getters.getRules
  },
  computed: {
    isDato () {
      return !(this.dato.trim() !== '')
    },
    isInformacion () {
      return !(this.objs.chk.length > 0)
    }
  },
  methods: {
    buscar () {
      this.rec_gestion_buscar_ids(this.dato)
    },
    asignar () {
      this.objs.dialogAsignar = true
    },
    cerrarVenta () {
      this.$emit('closedDialogoReasignacion')
    }

  }
}
</script>
