<template>
<div style="width:100%">
  <div v-for="(item, index) in items_cerrados" :key="index + 10000">
      <itemListaado :item="item" @viewPqr="verPqr" @openColores="abrirColores"></itemListaado>
      <v-divider></v-divider>
  </div>
  <div v-for="(item, index) in items_creados" :key="index + 20000">
      <itemListaado :item="item" @viewPqr="verPqr" @openColores="abrirColores"></itemListaado>
      <v-divider></v-divider>
    </div>
    <div v-for="(item, index) in items_escalamientos" :key="index + 30000">
      <itemListaado :item="item" @viewPqr="verPqr" @openColores="abrirColores"></itemListaado>
      <v-divider></v-divider>
    </div>
  <lecturaDialogo :gestion_id="gestion_id" :token="token" :colores="colores" :session="session" :dialogLectura="dialogLectura" @closed_ventana="cerrar_venta"></lecturaDialogo>
</div>
</template>

<script>
import itemListaado from '@/components/bandejas/bandeja_pqrs_listado_itemComponent.vue'
import lecturaDialogo from '@/components/inbound/inbound_lectura_dialogComponent.vue'
import recFunciones from '@/js/rec_funciones.js'
export default {
  name: 'bandeja_pqr_listadoComponent',
  components: {
    lecturaDialogo,
    itemListaado
  },
  mixins: [recFunciones],
  props: ['colores', 'session', 'items_escalamientos', 'items_creados', 'items_cerrados'],
  data: () => ({
    dialogLectura: false,
    token: 0,
    gestion_id: 0
  }),
  methods: {
    abrirColores () {
      // alert('1')
    },
    verPqr (item) {
      this.gestion_id = item.id
      this.token = this.token + 1
      this.dialogLectura = true
    },
    cerrar_venta () {
      this.dialogLectura = false
    }
  }
}
</script>
