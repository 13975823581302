import axios from 'axios'
export default {
  methods: {
    rec_modificaciones_datos () {
      /*
      if (this.form.escestado_id === 6) { // CERRAR CASO
        this.gestion.subestado_id = 4
        this.gestion.fechacerrado = null
      } else if (this.form.escestado_id === 7) {
        this.gestion.subestado_id = 5
        this.gestion.fechacerrado = null
      } else if (this.form.escestado_id === 11) { // ACEPTACION DE LA PAUSA
        this.gestion.subestado_id = 6
        this.gestion.fechacerrado = null
      } else if (this.form.escestado_id === 12) { // RECHAZO DE LA PAUSA
        this.gestion.subestado_id = 2
        this.gestion.fechacerrado = null
      } else if (this.form.escestado_id === 8) { // QUITAR PAUSA
        this.gestion.subestado_id = 2
        this.gestion.fechacerrado = null
      } else {
        this.gestion.subestado_id = 2
        this.gestion.fechacerrado = null
      }
      */
    },
    rec_modificaciones_guardar () {
      this.rec_modificaciones_datos()
      this.dialogo.pregunta = false
      this.dialogo.esperar = true
      var urlroute = this.$store.getters.getUrl + 'modificaciones'
      const data = {
        gestion_id: this.registro.id,
        gestion: this.gestion,
        registro: this.pqr,
        niveles: this.itemsObj.items_niveles,
        id_usuario: this.session.id,
        tiempos: this.alerta
      }
      axios({
        url: urlroute,
        method: 'POST',
        data: data,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        this.$emit('refresh_registro', response.data)
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.dialogo.esperar = false
      })
    },
    rec_modificaciones_reasignar () {
      this.dialogo.pregunta = false
      this.dialogo.esperar = true
      var urlroute = this.$store.getters.getUrl + 'reasignar'
      const data = {
        chk: this.objs.chk,
        id_usuario: this.asesor_id
      }
      axios({
        url: urlroute,
        method: 'POST',
        data: data,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        this.dialogo.finalizo = true
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.dialogo.esperar = false
      })
    }
  }
}
