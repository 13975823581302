<template>
  <div style="width:100%">
  <v-card>
    <v-card-text class="pt-1 pr-4 pl-4 pb-1">
        <v-row no-gutters>
          <v-col cols="12" md="4" class="pr-1">
            <v-text-field label="TIPO" :value="llamada.tipo" disabled></v-text-field>
          </v-col>
          <v-col cols="12" md="4" class="pr-1">
            <v-text-field label="TELEFONO" :value="llamada.celular" disabled></v-text-field>
          </v-col>
          <v-col cols="12" md="4" class="pr-1">
            <v-text-field label="UNIQUE ID" :value="llamada.uniqueid" disabled></v-text-field>
          </v-col>
        </v-row>
    </v-card-text>
  </v-card>
  <br>
  <buscarForm :rules="rules" :colores="colores" :session="session" :gestion="gestion" :items="items"></buscarForm>
  <br>
  <contactoForm :tokenNuevo="tokenNuevo" :alerta="alerta" :contacto="contacto" :rules="rules" :colores="colores" :session="session" :items="items" @nextTipificacion="siguienteTipificacion"></contactoForm>
  <br><br>
  <pqrForm v-if="showTipificacion" :tokenNuevo="tokenNuevo" :gestion="gestion" :contacto="contacto" :llamada="llamada" :segundos="tiempo" :items="items" :items_archivos="items_archivos" :session="session" :alerta="alerta" :rules="rules" :colores="colores" @add_archivos="agregar_archivos" @new_pqr="nuevo_pqr"></pqrForm>
  <br><br><br>
  <mensajeLogin :dialogoLogin="dialogoLogin" @close_login="cerrar_login"></mensajeLogin>
  </div>
</template>

<script>
import mensajeLogin from '@/components/widgets/mensajes_loginComponent.vue'
import contactoForm from '@/components/inbound/inbound_contactoComponent.vue'
import pqrForm from '@/components/inbound/inbound_pqrComponent.vue'
import buscarForm from '@/components/inbound/inbound_busquedaComponent.vue'
import recTipicaciones from '@/js/rec_tipicaciones.js'
import recGestiones from '@/js/rec_gestiones.js'
import recFuciones from '@/js/rec_funciones.js'

export default {
  name: 'dialogo_inboundComponent',
  components: {
    contactoForm,
    pqrForm,
    buscarForm,
    mensajeLogin
  },
  mixins: [recTipicaciones, recGestiones, recFuciones],
  props: ['tokenInbound', 'llamada', 'colores', 'loadingAtenciones', 'items', 'session', 'tiempo'],
  data: () => ({
    gestion: { keyform: 0, id_usuario: 0, id_cerrado: 0, categoria_id: null, subcategoria_id: null, detalle_id: null, descripcion: '', segundos: 0, subestado_id: 1, escalado_id: 1, fechadiagnostico: null, fechaescalado: null, fechafinalizado: null, fechaparacerrar: null, fechacerrado: null, tiempo: 0, fechadurocontacto: null, fechadurotipificacion: null, fechadurodiagnostico: null, tiempodiagnostico: 0 },
    contacto: { gestion_id: null, kit: '', divipole: '', departamento_id: null, ciudad_id: null, registraduria_id: null, direccion: '', tipo_id: null, operador_id: '', cedula: '', nombres: '', apellidos: '', celular: '', tiempo: 0 },
    alerta: { diagnostico: 0, tipod: '', escalado: 0, tipoe: '', finalizado: 0, tipof: '', total: 0, tipot: '', objCiudad: null, istransporte: 'NO' },
    loadingSubcategoria: false,
    items_subcategorias: [],
    rules: {},
    items_archivos: [],
    dialogoLogin: false,
    showTipificacion: false,
    tokenNuevo: 0
  }),
  created () {
    this.rules = this.$store.getters.getRules
  },
  watch: {
    tokenInbound: {
      immediate: true,
      handler (newVal, oldVal) {
        // this.rec_gestion_inicializacion_pqr()
        // this.rec_gestion_inicializacion_ciudadano()
        // this.rec_gestion_inicializacion_registrador()
        this.gestion.keyform = this.rec_funciones_key()
        this.items_archivos = []
      }
    }
  },
  methods: {
    siguienteTipificacion () {
      this.showTipificacion = true
    },
    agregar_archivos (data) {
      for (let i = 0; i < data.length; i++) {
        this.items_archivos.push(data[i])
      }
    },
    cerrar_login () {
      this.dialogoLogin = false
    },
    eliminarNiveles () {
      this.items_niveles = []
    },
    nuevo_pqr () {
      this.showTipificacion = false
      this.tokenNuevo = this.tokenNuevo + 1
      this.$emit('new_tiempo')
    }
  }
}
</script>
