<template>
  <div style="width:100%">
    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" :clipped-right="$vuetify.breakpoint.lgAndUp" app :color="colores.primario" dark>
      <v-toolbar-title class="ml-0 pl-4">
        <span>THOMAS GREG - SEMANA PUESTOS</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!--
      <v-btn icon>
        <v-badge color="red" overlap content="6"><v-icon>mdi-email</v-icon></v-badge>
      </v-btn>
      <v-btn icon>
        <v-badge color="red" overlap content="6"><v-icon>mdi-bell</v-icon></v-badge>
      </v-btn>
      -->
      <v-menu offset-y v-if="session.tipo === 'Externo'">
        <template v-slot:activator='{ on, attrs }'>
          <v-btn icon v-bind='attrs' v-on='on'><v-icon>mdi-account-circle</v-icon></v-btn>
        </template>
        <v-list>

          <v-container>
              <v-row no-gutters>
                <v-col cols="12" style="font-size:12px" class="text-center">
                  <v-icon size="70">mdi-account</v-icon>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" style="font-size:12px" class="text-center">
                  {{ session.nombres }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" style="font-size:12px" class="text-center">
                  {{ session.apellidos }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-btn @click="cerrar_session" x-small :color="colores.black" dark>CERRAR SESSION</v-btn>
                </v-col>
              </v-row>
          </v-container>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>

export default {
  name: 'MenuTop',
  props: ['colores', 'session'],
  data: () => ({
    drawer: true
  }),
  methods: {
    cerrar_session () {
      var id, identificacion, nombres, apellidos, correo, tipo, area, permisos
      id = 0
      identificacion = ''
      nombres = ''
      apellidos = ''
      correo = ''
      tipo = ''
      area = 0
      permisos = []
      // token = data.token
      this.$store.commit('setAutenticacion', false)
      this.$store.commit('setSession', { id, identificacion, nombres, apellidos, correo, tipo, area, permisos })
      // this.$store.commit('setToken', token)
      this.$router.replace({ path: '/' })
      // this.$router.replace({ path: 'bandeja?idusu=', query: { idusu: id } })
      // eslint-disable-next-line handle-callback-err
      this.$router.push('/').catch(err => {})
    }
  }
}
</script>
