<template>
<div style="width:100%">
  <v-card>
    <v-toolbar :color="colores.secundario" dark dense>
      <v-toolbar-title>CONTACTO</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="pt-1">
          <horaComponent v-if="showTiempo" :tokenTiempo="tokenInbound" :estadoTiempo="estadoTiempo" @eventotiempo="tiempo_pqr"></horaComponent>
          <v-btn v-if="!showTiempo" icon dark @click="editar"><v-icon>mdi-pencil</v-icon></v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text>
      <v-form ref="form">
        <v-row no-gutters>
            <v-col cols="12" md="6" class="pr-1">
              <v-text-field :disabled="!editable1" v-model="contacto.kit" label="* KIT REPORTADO" v-on:blur="blur_buscarkit(contacto.kit)" :success="isSuccess" maxlength="20" counter="20" :rules="[rules.requerido]" autocomplete="off">
                  <template v-slot:append>
                    <v-fade-transition leave-absolute>
                      <v-progress-circular v-if="isloadingKit" size="24" indeterminate></v-progress-circular>
                    </v-fade-transition>
                  </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field :disabled="!editable2" v-model="contacto.divipole" label="DIVIPOLE" maxlength="50" counter="50" :rules="[rules.requerido]" autocomplete="off"></v-text-field>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12" md="6" class="pr-1">
                <v-autocomplete :disabled="!editable2" v-model="contacto.departamento_id" label="* DEPARTAMENTO" :rules="[rules.requerido]" v-on:input="input_departamento(contacto.departamento_id)" item-text="descripcion" item-value="id" :loading="items.loading" :items="items.departamentos" autocomplete="off"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete :disabled="!editable2" v-model="alerta.objCiudad" label="* MUNICIPIO" :rules="[rules.requerido]" v-on:input="input_ciudad(alerta.objCiudad)" item-text="descripcion" item-value="id" :loading="loadingCiudad" :items="items_ciudades" autocomplete="off" return-object></v-autocomplete>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12" md="6" class="pr-1">
                <v-autocomplete :disabled="!editable2" v-model="contacto.registraduria_id" label="* REGISTRADURIA/PMI" :rules="[rules.requerido]" v-on:input="input_registraduria(contacto.registraduria_id)" item-text="descripcion" item-value="id" :loading="loadingRegistraduria" :items="items_registradurias" autocomplete="off"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" class="pr-1">
              <v-text-field :disabled="!editable2" readonly v-model="contacto.direccion" label="DIRECCION DE LA REGISTRADURIA" maxlength="250" counter="250"></v-text-field>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12" md="6" class="pr-1">
              <v-autocomplete :disabled="!editable2" v-model="contacto.tipo_id" label="* TIPO" :rules="[rules.requerido]" item-text="descripcion" item-value="id" :loading="items.loading" :items="items.tipos" autocomplete="off"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete :disabled="!editable2" v-model="contacto.operador_id" label="* OPERADOR INICIAL" :rules="[rules.requerido]" item-text="descripcion" item-value="id" :loading="items.loading" :items="items.operadores" autocomplete="off"></v-autocomplete>
            </v-col>
        </v-row>

        <v-row no-gutters>
            <v-col cols="12" md="6" class="pr-1">
              <v-text-field :disabled="!editable3" v-model="contacto.cedula" label="* CEDULA" v-on:blur="blur_buscarcedula(contacto.cedula)" :success="isSuccess" maxlength="20" counter="20" :rules="[rules.requerido]" autocomplete="off">
                  <template v-slot:append>
                    <v-fade-transition leave-absolute>
                      <v-progress-circular v-if="isloadingIden" size="24" indeterminate></v-progress-circular>
                    </v-fade-transition>
                  </template>
                </v-text-field>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12" md="6" class="pr-1">
                <v-text-field :disabled="!editable3" v-model="contacto.nombres" label="* NOMBRES" maxlength="50" counter="50" :rules="[rules.requerido]" autocomplete="off"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field :disabled="!editable3" v-model="contacto.apellidos" label="* CONTACTO" maxlength="50" counter="50" :rules="[rules.requerido]" autocomplete="off"></v-text-field>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12" md="3">
                <v-text-field :disabled="!editable3" v-model="contacto.celular" :rules="[rules.requerido]" label="* CELULAR" maxlength="10" counter="10"></v-text-field>
            </v-col>
        </v-row>
    </v-form>
    <br>
    <v-alert prominent v-if="showError" type="error">KIT NO EXISTENTE, POR FAVOR ESCRIBA UN KIT EXISTENTE.</v-alert>
    <br>
    <v-row v-if="showTiempo && editable4">
      <v-col cols="12">
        <v-btn @click="siguiente" block :color="colores.primario" dark>SIGUIENTE</v-btn>
      </v-col>
    </v-row>
    </v-card-text>
  </v-card>
  <mensajesSave :colores="colores" :dialogo="dialogo" :items_errores="items_errores" @closed_mensaje_dialogo="cerrar_mensaje_dialogo"></mensajesSave>
</div>
</template>

<script>
import recPaises from '@/js/rec_paises.js'
import recRegistradurias from '@/js/rec_registradurias.js'
import recContactos from '@/js/rec_contactos.js'
import horaComponent from '@/components/widgets/horaComponent.vue'
import mensajesSave from '@/components/widgets/mensajes_saveComponent.vue'
export default {
  name: 'dialogo_inboundComponent',
  components: {
    mensajesSave,
    horaComponent
  },
  mixins: [recPaises, recRegistradurias, recContactos],
  props: ['contacto', 'alerta', 'tokenNuevo', 'colores', 'rules', 'loadingDepartamento', 'items'],
  data: () => ({
    items_errores: [],
    dialogo: { incompleto: false, pregunta: false, pregunta_anular: false, esperar: false, finalizo: false, barfinalizo: false, barfinalizoanulo: false, detalle: false, error: false, objPayload: null },
    loadingCiudad: false,
    items_ciudades: [],
    loadingRegistraduria: false,
    items_registradurias: [],
    isSuccess: false,
    isloadingKit: false,
    isloadingIden: false,
    tiempo: 0,
    tokenInbound: 0,
    showTiempo: false,
    estadoTiempo: 2,
    editable1: false,
    editable2: false,
    editable3: false,
    editable4: false,
    showError: false
  }),
  watch: {
    tokenNuevo: {
      immediate: true,
      handler (newVal, oldVal) {
        this.showTiempo = false
        this.estadoTiempo = 2
        this.editable1 = false
        this.editable2 = false
        this.editable3 = false
        this.editable4 = false
        this.showError = false
      }
    }
  },
  methods: {
    tiempo_pqr (ptiempo) {
      this.contacto.tiempo = ptiempo
      this.tiempo = ptiempo
    },
    editar () {
      this.estadoTiempo = 1
      this.showTiempo = true
      this.editable1 = true
      this.editable2 = true
      this.editable3 = true
      this.editable4 = true
      this.showError = false
    },
    blur_buscarkit (pKit) {
      if (pKit !== '') {
        this.rec_contacto_kit(pKit)
      }
    },
    blur_buscarcedula (pCedula) {
      if (pCedula !== '') {
        this.rec_contacto_cedula(pCedula)
      }
    },
    siguiente () {
      if (this.$refs.form.validate()) {
        this.estadoTiempo = 3
        this.editable1 = false
        this.editable2 = false
        this.editable3 = false
        this.editable4 = false
        this.showError = false
        this.$emit('nextTipificacion')
      } else {
        this.dialogo.incompleto = true
      }
    },
    input_departamento (pDepartamentoId) {
      this.rec_paises_departamentos(pDepartamentoId)
    },
    input_ciudad (obj) {
      this.alerta.objCiudad = obj
      this.contacto.ciudad_id = obj.id
      this.rec_registraduria_ciudad(obj.id)
    },
    input_registraduria (pId) {
      this.rec_registraduria_id(pId)
    },
    cerrar_mensaje_dialogo (newvalue) {

    }

  }
}
</script>
