import axios from 'axios'
export default {
  methods: {
    rec_contacto_kit (pKit) {
      this.isloadingKit = true
      var urlroute = this.$store.getters.getUrl + 'kits_kit/' + pKit
      this.rec_contacto_inicializar_kit()
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        if (this.contacto.kit.trim() === '00000') {
          // this.rec_contacto_data_kit(response.data)
          this.rec_contacto_inicializar_kit()
          this.editable2 = true
          this.editable3 = true
          this.showError = false
        } else if (response.data !== '' && response.data !== null) {
          this.rec_contacto_data_kit(response.data)
          this.editable2 = false
          this.editable3 = true
          this.showError = false
        } else {
          this.rec_contacto_inicializar_kit()
          this.editable2 = false
          this.editable3 = false
          this.showError = true
        }
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.isloadingKit = false
      })
    },
    rec_contacto_inicializar_kit () {
      this.contacto.divipole = ''
      this.contacto.departamento_id = null
      this.alerta.objCiudad = null
      this.contacto.ciudad_id = null
      this.contacto.registraduria_id = null
      this.contacto.direccion = ''
      this.contacto.operador_id = null
      this.contacto.cedula = ''
      this.contacto.nombres = ''
      this.contacto.apellidos = ''
      this.contacto.celular = ''
    },
    rec_contacto_data_kit (data) {
      this.rec_paises_departamentos(data.departamento_id)
      this.rec_registraduria_ciudad(data.ciudad_id)
      this.contacto.divipole = data.divipole
      this.contacto.departamento_id = data.departamento_id
      this.contacto.ciudad_id = data.ciudad_id
      this.contacto.registraduria_id = data.registraduria_id
      this.contacto.direccion = data.direccion
      this.contacto.operador_id = data.operador_id
      this.contacto.tipo_id = data.tipo_id
      this.contacto.cedula = data.cedula
      this.contacto.nombres = data.nombres
      this.contacto.apellidos = data.apellidos
      this.contacto.celular = data.celular
      this.alerta.objCiudad = data.ciudad
      this.alerta.istransporte = 'NO'
    },
    rec_contacto_cedula (pCedula) {
      this.isloadingIden = true
      var urlroute = this.$store.getters.getUrl + 'contactos_cedula/' + pCedula
      this.rec_contacto_inicializar_cedula()
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        console.log(response.data)
        if (response.data !== '' && response.data !== null) {
          this.rec_contacto_data_cedula(response.data)
        }
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.isloadingIden = false
      })
    },
    rec_contacto_inicializar_cedula () {
      this.contacto.nombres = ''
      this.contacto.apellidos = ''
      this.contacto.celular = ''
    },
    rec_contacto_data_cedula (data) {
      this.contacto.nombres = data.nombres
      this.contacto.apellidos = data.apellidos
      this.contacto.celular = data.celular
    }
  }
}
