<template>
<div style="width:100%">
<v-form ref="form">
  <v-row no-gutters>
    <v-col cols="12" md="6" class="pr-1">
        <v-autocomplete v-model="gestion.categoria_id" :rules="[rules.requerido]" v-on:input="input_categoria(gestion.categoria_id)" item-text="descripcion" item-value="id" :items="items.categorias" :loading="items.loading" label="* CATEGORIA" autocomplete="off"></v-autocomplete>
    </v-col>
  </v-row>
  <v-row no-gutters>
    <v-col cols="12">
        <v-autocomplete v-model="gestion.subcategoria_id" :rules="[rules.requerido]" v-on:input="input_subcategoria(gestion.subcategoria_id)" item-text="descripcion" item-value="id" :items="items_subcategorias" :loading="loadingSubcategoria" label="* SUBCATEGORIA" autocomplete="off"></v-autocomplete>
    </v-col>
  </v-row>
  <v-row no-gutters>
    <v-col cols="12">
        <v-autocomplete v-model="objDetalle" :rules="[rules.requerido]" v-on:input="input_detalle(objDetalle)" item-text="descripcion" item-value="id" :items="items_detalles" :loading="loadingDetalle" label="* DETALLE" return-object autocomplete="off"></v-autocomplete>
    </v-col>
  </v-row>
  <br>
  <v-row no-gutters>
        <v-col cols="12" md="3" class="pr-2" v-for="(item, index) in itemsObj.items_niveles" :key="index">
            <nivelCard :color="item.color" :escestado="item.escestado" :titulo="'NIVEL ' + item.consecutivo" :descripcion="item.area"></nivelCard>
        </v-col>
  </v-row>
  <br>
  <v-row no-gutters>
    <v-col cols="12" md="6">
      <v-autocomplete v-model="objEstado" :rules="[rules.requerido]" v-on:input="input_estado(objEstado)" item-text="escestado.descripcion" item-value="escestado.id" :items="items_estados" label="ESTADO" return-object autocomplete="off"></v-autocomplete>
    </v-col>
  </v-row>
  <br>
  <v-row>
    <v-col cols="12" :md="pqr.detalle_id !== gestion.detalle_id?'6':'12'">
      <v-btn @click="cancelar" block :color="colores.primario" dark>CANCELAR</v-btn>
    </v-col>
    <v-col cols="12" md="6" v-if="pqr.detalle_id !== gestion.detalle_id">
      <v-btn @click="validar" block :color="colores.primario" dark>GUARDAR</v-btn>
    </v-col>
  </v-row>
</v-form>
<mensajesSave :colores="colores" :dialogo="dialogo" :items_errores="items_errores" @closed_mensaje_dialogo="cerrar_mensaje_dialogo"></mensajesSave>
</div>
</template>

<script>
import nivelCard from '@/components/inbound/inbound_nivelesComponent.vue'
import recTipicaciones from '@/js/rec_tipicaciones.js'
import recModificaciones from '@/js/rec_modificaciones.js'
import recNiveles from '@/js/rec_niveles.js'
import recFunciones from '@/js/rec_funciones.js'
import recForm from '@/js/rec_form.js'
import mensajesSave from '@/components/widgets/mensajes_saveComponent.vue'
export default {
  name: 'dialogo_inboundComponent',
  components: {
    nivelCard,
    mensajesSave
  },
  mixins: [recTipicaciones, recNiveles, recFunciones, recModificaciones, recForm],
  props: ['session', 'colores', 'registro', 'pqr'],
  data: () => ({
    items: { departamentos: [], busquedas: [], operadores: [], tipos: [], categorias: [], loading: false },
    rules: {},
    alerta: { diagnostico: 0, tipod: '', escalado: 0, tipoe: '', finalizado: 0, tipof: '', total: 0, tipot: '', objCiudad: null, istransporte: 'NO' },
    form: { escestado_id: null },
    gestion: { categoria_id: null, subcategoria_id: null, detalle_id: null, subestado_id: 1, escalado_id: 1, fechadiagnostico: null, fechaescalado: null, fechafinalizado: null, fechaparacerrar: null, fechacerrado: null },
    itemsObj: { items_niveles: [] },
    loadingSubcategoria: false,
    items_subcategorias: [],
    objEstado: null,
    items_estados: [],
    loadingSubCateroria: false,
    items_subcaterias: [],
    objDetalle: null,
    loadingDetalle: false,
    items_detalles: [],
    tiempo: 0,
    tokenInbound: 0,
    showTiempo: false,
    estadoTiempo: 2,
    editable: false,
    dialogoLogin: false,
    items_errores: [],
    dialogo: { incompleto: false, pregunta: false, pregunta_anular: false, esperar: false, finalizo: false, barfinalizo: false, barfinalizoanulo: false, detalle: false, error: false, objPayload: null }
  }),
  created () {
    this.rules = this.$store.getters.getRules
    this.rec_form_inbound()
  },
  methods: {
    validar () {
      if (this.$refs.form.validate()) {
        if (this.session.id === 0) {
          this.dialogo.incompleto = true
          this.dialogoLogin = true
        } else {
          this.dialogo.pregunta = true
        }
      } else {
        this.dialogo.incompleto = true
      }
    },
    cancelar () {
      this.$emit('edit_cancelar')
    },
    cerrar_mensaje_dialogo (newvalue) {
      if (newvalue === 'PREGUNTA') this.rec_modificaciones_guardar()
      else if (newvalue === 'CANCELARPREGUNTAR') this.dialogo.pregunta = false
      else if (newvalue === 'FINALIZO') {
        this.dialogo.finalizo = false
      } else if (newvalue === 'DETALLES') {
        this.dialogo.detalle = false
      }
    },
    input_categoria (pcategoriaId) {
      this.items_subcaterias = []
      this.items_detalles = []
      this.objEstado = null
      this.items_estados = []
      this.objDetalle = null
      this.rec_tipificaciones_categorias(pcategoriaId)
    },
    input_subcategoria (pSubCategoriaId) {
      this.items_detalles = []
      this.objDetalle = null
      this.items_estados = []
      this.objEstado = null
      this.rec_tipificaciones_detalles(pSubCategoriaId)
    },
    input_detalle (obj) {
      if (obj.accion !== '' && obj.accion !== null) {
        this.alerta.diagnostico = obj.accion.diasdiagnostico
        this.alerta.tipod = obj.accion.tipodiagnostico
        this.alerta.escalado = obj.accion.diasescalados
        this.alerta.tipoe = obj.accion.tipoescalados
        this.alerta.finalizado = obj.accion.diasfinalizado
        this.alerta.tipof = obj.accion.tipofinalizado
        this.alerta.total = obj.accion.diastotal
        this.alerta.tipot = obj.accion.tipototal
      } else {
        this.alerta.diagnostico = 0
        this.alerta.tipod = ''
        this.alerta.escalado = 0
        this.alerta.tipoe = ''
        this.alerta.finalizado = 0
        this.alerta.tipof = ''
        this.alerta.total = 0
        this.alerta.tipot = ''
      }

      this.objEstado = null
      this.items_estados = obj.niveles[0].gruestados
      this.crearNiveles(obj.niveles)
      this.gestion.detalle_id = obj.id
    },
    crearNiveles (pniveles) {
      this.eliminarNiveles()
      let color = 'blue-grey'
      let escestadoId = 1
      let escestado = 'EN ESPERA'

      for (let i = 0; i < pniveles.length; i++) {
        if (i === 0) {
          color = 'amber'
          escestadoId = 2
          escestado = 'EN PROCESO'
        } else {
          color = 'blue-grey'
          escestado = 'EN ESPERA'
          escestadoId = 1
        }
        this.agregarNiveles(pniveles[i], color, escestadoId, escestado)
      }
    },
    eliminarNiveles () {
      this.itemsObj.items_niveles = []
    },
    agregarNiveles (pniveles, color, escestadoId, escestado) {
      this.itemsObj.items_niveles.push({ gestion_id: null, fecha_asignado: null, fecha_cerrado: null, id_usuario: this.session.id, consecutivo: pniveles.consecutivo, area_id: pniveles.area.id, area: pniveles.area.descripcion, escestado_id: escestadoId, escestado: escestado, color: color })
    },
    eventosNiveles (pestadoId) {
      this.rec_niveles_nuevo(pestadoId)
    },
    input_estado (obj) {
      this.gestion.escalado_id = 0
      this.gestion.subestado_id = 0
      this.gestion.fechacerrado = null
      if (obj.escestado_id === 6) { // CERRAR CASO
        this.gestion.escalado_id = 1
        this.gestion.subestado_id = 1
        this.gestion.fechacerrado = null
      } else if (obj.escestado_id === 9) { // VALIDACION
        this.gestion.escalado_id = 3
        this.gestion.subestado_id = 3
        this.gestion.fechacerrado = null
      } else {
        this.gestion.escalado_id = 2
        this.gestion.subestado_id = 2
        this.gestion.fechacerrado = null
      }
      this.eventosNiveles(obj.escestado_id)
    }
  }
}
</script>
