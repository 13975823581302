import axios from 'axios'
export default {
  methods: {
    rec_form_inbound () {
      this.loadingAtenciones = true
      this.items_atenciones = []
      var urlroute = this.$store.getters.getUrl + 'form_inbound'
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        this.items.departamentos = response.data.departamentos
        this.items.busquedas = response.data.busquedas
        this.items.categorias = response.data.categorias
        this.items.operadores = response.data.operadores
        this.items.tipos = response.data.tipos
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingAtenciones = false
      })
    },
    rec_form_backoffice () {
      this.loadingAtenciones = true
      this.items_estados = []
      var urlroute = this.$store.getters.getUrl + 'form_backoffice'
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        this.items_estados = response.data.estados
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingAtenciones = false
      })
    },
    rec_form_seguimientos () {
      this.loadingAtenciones = true
      this.items_seguimientos = []
      var urlroute = this.$store.getters.getUrl + 'form_seguimientos'
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        this.items_seguimientos = response.data.seguimientos
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingAtenciones = false
      })
    },
    rec_form_alertas () {
      var urlroute = this.$store.getters.getUrl + 'form_alertas'
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        this.items_niveles = response.data.niveles
        this.items_areas = response.data.areas
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingDetalle = false
      })
    },
    rec_form_asignar () {
      const urlroute = this.$store.getters.getUrl + 'form_asignaciones'
      this.items_asesores = []
      this.loadingAsesor = true
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        this.items_asesores = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingAsesor = false
      })
    }

  }
}
