<template>
<div style="width:100%">
  {{ validarPermisos(8) }}
  <v-card>
    <v-toolbar :color="colores.secundario" dark dense>
      <v-toolbar-title>TIPIFICACION</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom v-if="validarPermisos(8)===true && !showBackOffice===true && (pqr.subestado_id===2 || pqr.subestado_id===4 || pqr.subestado_id===5 || pqr.subestado_id===6)">
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="abrirPorBackcoffice" v-bind="attrs" v-on="on" icon>
            <v-icon>mdi-charity</v-icon>
          </v-btn>
        </template>
        <span>Cierre Backoffice</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="editar" v-bind="attrs" v-on="on" v-if="validarPermisos(7) && !showEditar && (pqr.subestado_id===2)" icon>
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Editar Incidente</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text v-if="!showEditar">
      <v-row no-gutters>
          <v-col cols="12">
            <v-text-field label="CATEGORIA" :value="registro.categoria.descripcion" filled readonly></v-text-field>
          </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <v-text-field label="SUBCATEGORIA" :value="registro.subcategoria.descripcion" filled readonly></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <v-text-field label="DETALLE" :value="registro.detalle.descripcion" filled readonly></v-text-field>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row no-gutters>
          <v-col cols="12">
            <textComponent :titulo="'DESCRIPCION'" :descripcion="registro.descripcion"></textComponent>
          </v-col>
      </v-row>
      <label class="text-h5 blue--text" v-if="registro.gesarchivos.length > 0"><strong>ARCHIVOS</strong></label>
      <v-list dense>
          <div v-for="(item, index) in registro.gesarchivos" :key="index">
            <filesLectura :colores="colores" :tipo="'GESTION'" :item="item"></filesLectura>
            <v-divider></v-divider>
          </div>
      </v-list>
      <br><br>
      <nivelesLectura :escalamientos="items_niveles"></nivelesLectura>
      <br>
      <backofficeCerrarComponent v-if="showBackOffice" :colores="colores" :registro="registro" :session="session" @backoffce_cancelar="backoffce_cancelar" @backoffce_registro="backoffce_registro"></backofficeCerrarComponent>
      <formGestionComponent v-else-if="validar_area" :colores="colores" :items_niveles="items_niveles" :keyform="keyform" :session="session" :registro="registro" :pqr="pqr" :items_estados="items_estados" @update_registro="actualizar_registro" @update_seguimiento="actualizar_seguimiento"></formGestionComponent>
      <div v-else>
        <formSeguimientoComponent v-if="pqr.subestado_id !== 1" :colores="colores" :keyform="keyform" :session="session" :registro="registro" :pqr="pqr" :items_estados="items_estados" @update_seguimiento="actualizar_seguimiento"></formSeguimientoComponent>
      </div>
    </v-card-text>
    <v-card-text v-else>
      <pqrEditarComponent :colores="colores" :registro="registro" :pqr="pqr" :session="session" @edit_cancelar="editar_cancelar" @refresh_registro="modificacion_registro"></pqrEditarComponent>
    </v-card-text>
    <br><br><br><br>
  </v-card>
  <br>
</div>
</template>

<script>
import filesLectura from '@/components/inbound/inbound_lectura_archivosComponent.vue'
import nivelesLectura from '@/components/inbound/inbound_lectura_nivlesComponent.vue'
import formGestionComponent from '@/components/inbound/inbound_lectura_pqr_form1Component.vue'
import pqrEditarComponent from '@/components/inbound/inbound_pqr_editarComponent.vue'
import backofficeCerrarComponent from '@/components/backoffice/backoffice_cerrarComponent.vue'
import formSeguimientoComponent from '@/components/inbound/inbound_lectura_pqr_form2Component.vue'
import textComponent from '@/components/widgets/textComponent.vue'

import recNiveles from '@/js/rec_niveles.js'
import recFunciones from '@/js/rec_funciones.js'
import recLogs from '@/js/rec_logs.js'
export default {
  name: 'inbound_lectura_pqrComponent',
  components: {
    textComponent,
    nivelesLectura,
    formSeguimientoComponent,
    formGestionComponent,
    filesLectura,
    pqrEditarComponent,
    backofficeCerrarComponent
  },
  mixins: [recNiveles, recLogs, recFunciones],
  props: ['colores', 'items_archivos', 'keyform', 'session', 'registro', 'pqr', 'items_estados', 'items_niveles'],
  data: () => ({
    alerta: { diagnostico: 0, tipod: '', escalado: 0, tipoe: '', finalizado: 0, tipof: '', total: 0, tipot: '', objCiudad: null, istransporte: 'NO' },
    gestion: { subestado_id: 1, fechadiagnostico: null, fechaescalado: null, fechafinalizado: null, fechaparacerrar: null, fechacerrado: null },
    form: { gestion_id: 0, id_usuario: 0, escestado_id: 0, descripcion: '' },
    rules: {},
    items_errores: [],
    showErrorPausa: false,
    showEditar: false,
    showBackOffice: false,
    dialogo: { incompleto: false, pregunta: false, pregunta_anular: false, esperar: false, finalizo: false, barfinalizo: false, barfinalizoanulo: false, detalle: false, error: false, objPayload: null }
  }),
  created () {
    this.rules = this.$store.getters.getRules
  },
  computed: {
    validar_area () {
      let estado = false
      if (this.pqr.subestado_id === 4 && this.pqr.id_usuario === this.session.id) {
        estado = true
      } else {
        for (let i = 0; i < this.registro.escalamientos.length; i++) {
          if ((this.pqr.escalamientos[i].area_id === this.session.area && this.pqr.escalamientos[i].escestado_id === 2 && this.pqr.escalamientos[i].escestado_id !== 7)) {
            estado = true
          }
          if (this.session.area !== 100 && this.pqr.escalamientos[i].id_usuario === this.session.id && this.session.tipo === 'Interno' && this.pqr.escalamientos[i].consecutivo === 1 && this.pqr.escalamientos[i].escestado_id === 2) {
            estado = true
          }
          if ((this.session.area === 100 && (this.pqr.escalamientos[i].escestado_id === 7 || this.pqr.escalamientos[i].escestado_id === 11))) {
            estado = true
          }
          if (this.session.area !== 100 && this.session.tipo === 'Interno' && this.pqr.escalamientos[i].consecutivo === 1 && this.pqr.escalamientos[i].escestado_id === 9) {
            estado = true
          }
        }
      }
      return estado
    }
  },
  methods: {
    validarPermisos (AplicativoId) {
      let estado = false
      const permisos = this.$store.getters.getSession.permisos
      for (let i = 0; i < permisos.length; i++) {
        if (permisos[i].aplicativo_id === AplicativoId) {
          estado = true
          break
        }
      }
      return estado
    },
    editar () {
      this.showBackOffice = false
      this.showEditar = true
    },
    editar_cancelar () {
      this.showEditar = false
    },
    backoffce_registro (data) {
      this.$emit('update_registro', data)
      this.showBackOffice = false
      this.showEditar = false
    },
    backoffce_cancelar () {
      this.showBackOffice = false
      this.showEditar = false
      console.log(this.$store.getters.getSession.permisos)
      console.log(this.pqr)
    },
    actualizar_seguimiento (data) {
      this.$emit('update_registro', data)
    },
    actualizar_registro (data) {
      this.$emit('update_registro', data)
    },
    modificacion_registro (data) {
      this.$emit('update_registro', data)
      this.editar_cancelar()
    },
    abrirPorBackcoffice () {
      this.showEditar = false
      this.showBackOffice = true
    },
    cerrarPorBackcoffice () {
      this.showBackOffice = false
    }
  }
}
</script>
